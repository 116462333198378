export const errorHandler = (error) => {
    if (error.response) {
        // Log the entire response for debugging purposes
        console.error('Response Error Data:', error.response.data);
        console.error('Response Error Status:', error.response.status);
        console.error('Response Error Headers:', error.response.headers);

        // Try to extract and throw the error message
        const errorMessage = error.response.data.message || error.response.data.error || 'Unknown error occurred';
        throw new Error(errorMessage);
    } else if (error.request) {
        // Log the request for debugging purposes
        console.error('No response received:', error.request);
        throw new Error('No response received from the server');
    } else {
        // Log the error message for debugging purposes
        console.error('Error setting up the request:', error.message);
        throw new Error(error.message);
    }
};