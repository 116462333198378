import axios from "axios";
import {base_url, getTokenFromLocalStorage, withErrorHandlingAndLoading} from "./functions";
import {CardCvcElement} from "@stripe/react-stripe-js";
import {errorHandler} from "./errorHandler";

const payment_url = base_url + '/payments';
axios.defaults.headers.common['Authorization'] = getTokenFromLocalStorage();

export const publishableKeyGet = async () => {
    try {
        const response = await axios.get(`${payment_url}/publishable-key`);
        const {pk} = response.data;
        return pk;
    } catch (error) {
        errorHandler(error);
    }
}
export const methodsGet = async (customerId) => {
    try {
        const response = await axios.get(`${payment_url}/methods/${customerId}`);

        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const clientSecretPull = async (customerId) => {
    try {
        const response = await axios.post(`${payment_url}/create-setupintent`, {customerId}, {
            headers: {'Content-Type': 'application/json',}
        });
        return response.data.clientSecret;
    } catch (error) {
        errorHandler(error);
    }
}
export const attachPaymentMethod = async (customerId, methodId) => {
    try {
        const response = await axios.post(`${payment_url}/attach-payment-method/${customerId}`, {
            methodId,
        }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const createSubscription = async (customerId, priceId) => {
    try {
        const response = await axios.post(`${payment_url}/create-subscription/${customerId}`, {
            priceId
        });

        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const fetchProducts = async () => {
    try {
        const response = await axios.get(`${payment_url}/products`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const fetchCustomerInfo = async (customerId) => {
    try {
        const response = await axios.get(`${payment_url}/customer-info/${customerId}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const changePlan = async (customerId) => {
    try {
        const response = await axios.put(`${payment_url}/subscription-continuation/${customerId}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const performPayment = async (customerId, currentMethod, priceId) => {
    try {
        await axios.post(`${payment_url}/confirm-payment/${customerId}`, {
            paymentMethod: currentMethod,
            priceId
        })
    } catch (error) {
        errorHandler(error);
    }
}
export const addProduct = async (product) => {
    try {
        const response = await axios.post(`${payment_url}/add-product`, product);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const updateProduct = async (metadata) => {

    try {
        const response = await axios.put(`${payment_url}/update-product`, metadata);
        return response.data; // Return the data from the response if needed
    } catch (error) {
        errorHandler(error);
    }
}
export const archiveProduct = async (productId, active) => {
    try {
        const response = await axios.put(`${payment_url}/archive-product/${productId}`, {active});
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const listInvoices = async (customerId, limit= 10) => {
    try {
        const response = await axios.get(`${payment_url}/invoices/${customerId}?limit=${limit}`, {
            headers: {'Content-Type': 'application/json',}
        });
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}


export const deleteMethod = async (customerId,methodId) => {
    try {
        const response = await axios.post(`${payment_url}/delete-payment-method/${customerId}`, {customerId, paymentMethodId: methodId});
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const updateMethod = async (paymentMethodId, customerId, cardDetails) => {
    try {
        const response = await axios.post(`${payment_url}/update-payment-method/${customerId}`, {paymentMethodId,customerId,cardDetails});
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const setDefaultMethod = async (customerId, methodId) => {
    try {
        const response = await axios.post(`${payment_url}/set-default-method/${customerId}`, { customerId, paymentMethodId: methodId});
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
