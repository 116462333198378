import {useStateValue} from "../../StateContext";
import React, {useCallback, useState} from "react";
import {addFlow, updateFlow, withErrorHandlingAndLoading} from "../../constants/functions";
import {actionTypes} from "../../StateContext/types";
import {Button, DialogActions, DialogContentText, TextField} from "@material-ui/core";
import Loading from "../../Components/Loading";
import Box from "@material-ui/core/Box";
import {getToken} from "../../Utils/common";
import DialogHandler from "../../Components/DialogHandler";
import {IFrame} from "./IFrame";

export const FlowForm = ({
                             flowControl,
                             name,
                             setProgress,
                             handleAlert,
                             handleDialogClose,
                             flow,
                             addProcessFlow,
                             progress,
                             history,
                             isProcess
                         }) => {
    const { flowsDispatch } = useStateValue();
    const [flowName, setFlowName] = useState(name || '');
    const [showFlow, setShowFlow] = useState(false);
    const [containerName, setContainerName] = useState('');
    const token = getToken();
    const [isLoaded, setIsLoaded] = useState(false);

    function handleIframeLoad() {
        setIsLoaded(true);
    }

    const handleChange = useCallback((event) => setFlowName(event.target.value), []);

    const handleAdd = withErrorHandlingAndLoading(async () => {
        const response = await addFlow({ name: flowName });
        flowsDispatch({
            type: actionTypes.ADD_FLOW,
            value: response.flow
        });
        setContainerName(response.flow.containerName);
        addProcessFlow && addProcessFlow({ flowName, containerName: response.flow.containerName });

        if (isProcess) {
            setShowFlow(true); // Show the iframe dialog
        } else {
            history.push(`/nodered/${response.flow.containerName}`); // Redirect to the URL
        }

        handleDialogClose(); // Close the "Add Flow" dialog
    }, setProgress, handleAlert);

    const updateFlowName = withErrorHandlingAndLoading(async () => {
        await updateFlow(flow?.id, flowName);
        flowsDispatch({
            type: actionTypes.UPDATE_FLOW,
            flowId: flow?.id
        });
    }, setProgress, handleAlert);

    return (
        <>

            <DialogHandler open={flowControl.add || flowControl.edit} maxWidth={"sm"}
                           title={flowControl.add ? 'New Flow' : flowControl.edit ? 'Update Flow' : ""}>
                <DialogContentText>Enter Flow Name :</DialogContentText>
                {progress ? <Loading size={12}/> : null}

                <TextField
                    autoFocus
                    value={flowName}
                    margin="dense"
                    label="Flow Name"
                    fullWidth
                    onChange={handleChange}
                />

                <DialogActions>
                    <Button
                        color="primary"
                        onClick={() => {
                            setContainerName('');
                            handleDialogClose(flowControl.add ? 'add' : 'edit');
                        }}
                    >
                        Close
                    </Button>
                    <Button onClick={flowControl.add ? handleAdd : updateFlowName} color="primary">
                        {flowControl.add ? 'Add' : 'Update'}
                    </Button>
                </DialogActions>
            </DialogHandler>

            <DialogHandler open={showFlow} maxWidth={"lg"} onClose={() => setShowFlow(false)} title={`Flow : ${flowName}`}>
                <Box display="flex" justifyContent="center">
                    {isLoaded ? <></> : <Loading/>} {/* Show Loading until iframe is loaded */}
                </Box>

                <IFrame containerName={containerName} token={token} onLoad={handleIframeLoad}/>
            </DialogHandler>
        </>
    );
};
