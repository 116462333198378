import React, { useCallback, useState } from "react";
import {
  setNotifications,
  setUserSession,
} from "../../Utils/common";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Link as RouterLink } from "react-router-dom";
import { withErrorHandlingAndLoading } from "../../constants/functions";
import MessageAlert from "../../Components/Alert";
import Loading from "../../Components/Loading/Loading";
import { login } from "../../constants/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  paper: {
    marginTop : 40,
    width: '100%',
    maxWidth: 600,
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    margin : 5 ,
    '&:hover': {
      textDecoration: "underline", // Underline on hover
      color: theme.palette.secondary.main,
    },
  },
}));

const SignIn = () => {
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const handleAlert = useCallback((open, message, severity) => {
    setAlert({ open, message, severity });
  }, []);

  const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

  const username = useFormInput("");
  const password = useFormInput("");
  const classes = useStyles();

  const handleLogin = withErrorHandlingAndLoading(async () => {
    const response = await login({ identifier: username.value, password: password.value });
    setUserSession(response.token, response.user, {});
    setNotifications({});
    window.location.reload();
  }, setLoading, handleAlert);

  const handleKeyDown = async (event) => {
    if (event.key === "Enter") await handleLogin();
  };

  return (
      <Container className={classes.root} component="main" maxWidth="xs">
        <CssBaseline />
        <MessageAlert
            open={alert.open}
            message={alert.message}
            onClose={handleClose}
            severity={alert.severity}
        />
        <div className={classes.paper}>
          <div style={{display:'flex', justifyContent:'center'}}>
            <img
                src={"signin.png"}
                alt={"BeeMetrix"}
                width={"250px"}
            />
          </div>

          <div className={classes.form}>
            <TextField
                type="text"
                {...username}
                autoComplete="username"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username/Email"
                name="username"
                onKeyDown={handleKeyDown}
                autoFocus
                size={"small"}
            />
            <TextField
                type="password"
                {...password}
                autoComplete="new-password"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                onKeyDown={handleKeyDown}
                id="password"
                label="Password"
                name="password"
                size={"small"}
            />

            <div style={{display:'flex', justifyContent:'space-between'}}>
              <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
              />

              <Link component={RouterLink} to="/request-token?type=password-reset" className={classes.link}>
                Forgot password ?
              </Link>
            </div>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleLogin}
                disabled={loading}
            >
              {loading ? "Loading..." : "Sign In"}
            </Button>
            {loading && <Loading />}

            <p>
              Don't have an account?
              <Link component={RouterLink} to="/signup" className={classes.link}>
                Register Now
              </Link>
            </p>

          </div>
        </div>
      </Container>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => setValue(e.target.value);
  return { value, onChange: handleChange };
};

export default SignIn;
