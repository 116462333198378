import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Box,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import {
    SentimentVerySatisfied,
    SentimentVeryDissatisfied
} from '@material-ui/icons';
import StepperIcons from "./StepperIcons";
import ContactForm from "./Forms/ContactForm";
import PaymentForm from "./Forms/PaymentForm";
import {
    useStripe,
    useElements,
    CardCvcElement,
} from '@stripe/react-stripe-js';
import StepConnector from './StepConnector'
import { attachPaymentMethod, clientSecretPull} from "../../../../constants/stripe";
import Loading from "../../../../Components/Loading/Loading";
import {useStateValue} from "../../../../StateContext";
import {withErrorHandlingAndLoading} from "../../../../constants/functions";

// OVERALL STYLE
const style = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {
        position: "relative",
        marginTop: "-8px",
        padding: "10px 20px",
        borderBottomRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: theme.palette.background.default
    },
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    buttonWrapper: {
        justifyContent: "flex-end"
    },
}));

const StepContent = ({ step }) => {
    switch (step) {
        case 0:
            return <ContactForm />;
        case 1:
            return <PaymentForm />;
        // case 2:
        //     return <PaymentForm />;
        default:
            return <></>;
    }
}

const Steppers = ({ stripe, handleAlert, customerId, closeStepper }) => {
    const classes = style();
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cardStatus, setCardStatus] = useState(true);
    const [cardMessage, setCardMessage] = useState("");
    const { formValuesState }= useStateValue();
    const elements = useElements();

    const handleNext = async () => {
        if (activeStep === 1) { await addMethod() }
        else {setActiveStep(prevActiveStep => prevActiveStep + 1);}
    };

    const handleBack = () => {
        if (activeStep === 0) closeStepper();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
    const handleReset = () => {
        setActiveStep(0);
        closeStepper();
    }

    const addMethod = withErrorHandlingAndLoading(async () => {
            const clientSecret = await clientSecretPull(customerId);
            const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardCvcElement),
                    billing_details: {
                        address: {
                            line1: formValuesState.line1,
                            line2: formValuesState.line2,
                            city: formValuesState.city,
                            state: formValuesState.state,
                            postal_code: formValuesState.postal_code,
                            country: formValuesState.address_country,
                        },
                        name: `${formValuesState.firstname} ${formValuesState.lastname}`,
                        email: formValuesState.email, // Optional, if you collect an email
                    }
                }
            });
            if (error) {
                handleAlert( true, `Error confirming SetupIntent: ${error}`,'error');
            } else if (setupIntent) {
                // Card saved successfully
                await attachPaymentMethod(customerId, setupIntent.payment_method);
                handleAlert( true, `Card saved successfully.`,'success');
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
    }, setLoading, handleAlert);

    return (
        <>
            <Stepper alternativeLabel className={classes.stepper} connector={<StepConnector />} activeStep={activeStep}>
                {/* Change the number of loops here based on StepContent */}
                {[1, 2].map(e =>
                    <Step key={e}>
                        <StepLabel StepIconComponent={StepperIcons} />
                    </Step>
                )}
            </Stepper>
            <Box className={classes.mainBox}>
                {activeStep === 2 ?
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                        justifyContent="space-around"
                        alignItems="center"
                        style={{ height: "400px" }}
                    >
                        {cardStatus
                            ?
                            <SentimentVerySatisfied fontSize="large" color="primary" />
                            :
                            <SentimentVeryDissatisfied fontSize="large" color="error" />
                        }
                        <Typography variant="h4">
                            {cardMessage}
                        </Typography>
                        <Button onClick={cardStatus ? handleReset : handleBack} className={classes.button}>
                            {cardStatus ? "Return" : "Back"}
                        </Button>
                    </Grid>
                    :
                    <form autoComplete="off" className={classes.form} onSubmit={e => { e.preventDefault(); handleNext() }}>
                        <Grid container spacing={3}>
                            <StepContent step={activeStep} />
                            <Grid container item justifyContent="flex-end">
                                <Button  className={classes.button} onClick={handleBack}>
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.button}
                                    type="submit"
                                    disabled={loading}
                                >
                                    {
                                        loading
                                            ?
                                            <Loading size={24} />
                                            :
                                            activeStep === 1 ? 'Add' : 'Next'
                                    }
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Box>
        </>
    );
}

export default Steppers;
