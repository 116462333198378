import React, {useEffect, useState} from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { StateProvider } from "./StateContext";
import MessageAlert from "./Components/Alert";
import {getTokenFromLocalStorage} from "./constants/functions";
import Layout from "./Layout";

import Routes from "./Routes";

const browserHistory = createBrowserHistory();
const routes = Routes();

const theme = createTheme({
    palette: {
        primary: {
            main: '#1C4E80', // Replace this with your desired primary color
        },
    },
});

export default function App() {

    const [alert, setAlert] = useState({
        open : false , message : "" , severity : ""
    });

    const handleClose = () => {setAlert({ open : false , message : "" , severity : "info"})};

    const handleAlert = (open, message, severity) =>{
        setAlert({
            open : open ,
            message :message ,
            severity: severity
        })
    }

    useEffect(() => {
        const token = getTokenFromLocalStorage();
        if(token) {
            const ws = new WebSocket(process.env.REACT_APP_WS_URL ,  [token.split(' ')[1]]);

            ws.addEventListener('open', () => {
                console.log('Connected to server');
            });

            ws.addEventListener('message', (event) => {
                console.log('Received message:', event.data);
                handleAlert(true , event.data , 'info')
            });

            ws.addEventListener('ping', (event) => {
                ws.pong();
                console.log('Received ping, sent pong');
            });

            return () => {
                ws.close(); // Disconnect when component unmounts
            };
        }
    }, []);

    return (
        <MuiThemeProvider theme={theme}>
            <StateProvider>
                <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity}/>
                <Router history={browserHistory}>
                    <Layout routes={routes} />
                </Router>
            </StateProvider>
        </MuiThemeProvider>
    );
}