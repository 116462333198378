import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Loading from "../../Components/Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        marginTop: 40,
        width: '100%',
        maxWidth: 600,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

const PasswordForm = ({ onSubmit, loading, title }) => {
    const classes = useStyles();
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const newPassword = useFormInput("");
    const confirmPassword = useFormInput("");

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(newPassword.value, confirmPassword.value);
    };

    return (
        <Container className={classes.root} component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography variant={"h6"}>
                    {title}
                </Typography>
                <TextField
                    type={showNewPassword ? "text" : "password"}
                    {...newPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="New Password"
                    name="newPassword"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowNewPassword((prev) => !prev)}
                                    edge="end"
                                >
                                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    type={showConfirmPassword ? "text" : "password"}
                    {...confirmPassword}
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Confirm Password"
                    name="confirmPassword"
                    size="small"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() => setShowConfirmPassword((prev) => !prev)}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? <Loading/> : "Submit"}
                </Button>
            </div>
        </Container>
    );
};

// Custom hook for form inputs
const useFormInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);
    const handleChange = (e) => setValue(e.target.value);
    return { value, onChange: handleChange };
};

export default PasswordForm;
