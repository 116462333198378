import React from 'react';
import { CircularProgress } from '@material-ui/core';

const Loading = ({size}) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin : 10 }}>
            <CircularProgress size={20} />
        </div>
    );
};

export default Loading;
