import { actionTypes } from './types';

export const  initialFormValuesState =   {
        date: "",
        service: "",
        facebook: "",
        twitter: "",
        firstname: "",
        lastname: "",
        email: "",
        line1: "",
        line2: "",
        postal_code: "",
        city: "",
        state:"",
        country: null,
        currency: null,
        amount: "",
};

// Define separate reducers for each state slice
export const formValuesReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.EDIT_FORM_VALUE:
            return {
                ...state,
                [action.key.toLowerCase()]: action.value
            };
        case actionTypes.EMPTY_FORM_VALUE:

            return initialFormValuesState;
        default:
            return state;
    }
};

export const metadataReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.EDIT_METADATA_VALUES:
            return {
                ...state,
                [action.key.toLowerCase()]: action.value
            };
        case actionTypes.SET_METADATA_VALUES:
            return action.value;
        default:
            return state;
    }
};

export const productsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_PRODUCTS:
            return action.value;
        case actionTypes.UPDATE_PRODUCT:
            return state.map(product => {
                return product.id === action.productId ? { ...product, ...action.value } : product;
            });
        case actionTypes.ADD_PRODUCT:
            return [...state, action.product];
        case actionTypes.ARCHIVE_PRODUCT:
            return state.map(product => {
                return product.id === action.productId ? { ...product, active : action.active } : product;
            });
        default:
            return state;
    }
};

export const usersReducer = (state, action) => {
    console.log(action)
    switch (action.type) {
        case actionTypes.SET_USERS:
            return action.value;
        case actionTypes.ADD_USER:
            return [...state, action.value];
        case actionTypes.DELETE_USER:
            return state.filter(user => user.id !== action.userId);
        case actionTypes.UPDATE_USER:
            return state.map(user => {
                return user.id === action.userId ? { ...user, ...action.value } : user;
            });
        default:
            return state;
    }
};

export const billingAddressesReducer = (state, action) => {
    console.log(action)
    switch (action.type) {
        case actionTypes.SET_ADDRESSES:
            return action.value;
        case actionTypes.ADD_ADDRESS:
            return [...state, action.value];
        case actionTypes.DELETE_ADDRESS:
            return state.filter(address => address.id !== action.addressId);
        case actionTypes.UPDATE_ADDRESS:
            return state.map(address => {
                return address.id === action.addressId ? { ...address, ...action.value } : address;
            });
        default:
            return state;
    }
};

export const paymentMethodsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_METHODS:
            return action.value;
        case actionTypes.ADD_METHOD:
            return [...state, action.value];
        case actionTypes.DELETE_METHOD:
            return state.filter(method => method.id !== action.methodId);
        case actionTypes.UPDATE_METHOD:
            return state.map(method => {
                return method.id === action.methodId ? { ...method, ...action.value } : method;
            });
        default:
            return state;
    }
}

export const connectorsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_CONNECTORS:
            return action.value;
        case actionTypes.UPDATE_CONNECTOR:
            return state.map(connector => {
                return connector.name === action.connectorName ? { ...connector, ...action.value } : connector;
            });
        case actionTypes.ADD_CONNECTOR:
            return [...state, action.connector];
        case actionTypes.DELETE_CONNECTOR:
            return state.filter(connector => connector.id !== action.value);
        default:
            return state;
    }
};

export const connectorInfoReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_CONNECTOR_INFO:
            return action.value;
        default:
            return state;
    }
};

export const drawerReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.OPEN_DRAWER:
            return true;
        case actionTypes.CLOSE_DRAWER:
            return false;
        default:
            return state;
    }
};

export const kafkaTopicsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_TOPICS:
            return action.value;
        case actionTypes.ADD_TOPIC:
            return [...state, action.topic];
        case actionTypes.DELETE_TOPIC:
            return state.filter(topic => topic.id !== action.topicId);
        case actionTypes.DELETE_TOPICS:
            return state.filter(topic => !action.topicIds.includes(topic.id));
        case actionTypes.UPDATE_TOPIC:
            return state.map(topic => {
                return topic.id === action.topicId ? { ...topic, ...action.value } : topic;
            });
        default:
            return state;
    }
};

export const thingsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_THINGS:
            return action.value;
        case actionTypes.ADD_THING:
            return [...state, action.thing];
        case actionTypes.DELETE_THING:
            return state.filter(thing => thing.thing_id !== action.thingId);
        case actionTypes.UPDATE_THING:
            return state.map(thing => {
                return thing.thing_id === action.thingId ? { ...thing, name: action.name } : thing;
            });
        default:
            return state;
    }
};

export const databasesReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_DATABASES:
            return action.value;
        case actionTypes.ADD_DATABASE:
            return [action.database, ...state];
        case actionTypes.DELETE_DATABASE:
            return state.filter(db => db.id !== action.id);
        case actionTypes.UPDATE_DATABASE:
            return state.map(db => {
                return db.id === action.id ? { ...db, ...action.value } : db;
            });
        default:
            return state;
    }
};

export const notificationsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_NOTIFICATIONS:
            return action.value;
        case actionTypes.ADD_NOTIFICATION:
            return [...state, action.notification];
        case actionTypes.DELETE_NOTIFICATIONS:
            return state.filter(notification => !action.topicIds.includes(notification.id));
        case actionTypes.UPDATE_NOTIFICATION:
            return state.map(notification => {
                return notification.id === action.id ? { ...notification, seen : 1 } : notification;
            });
        default:
            return state;
    }
};

export const flowsReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_FLOWS:
            return action.value;
        case actionTypes.ADD_FLOW:
            return [...state, action.value];
        case actionTypes.DELETE_FLOW:
            return state.filter(flow => flow.id !== action.flowId);
        case actionTypes.DELETE_FLOWS:
            return state.filter(flow => !action.flowIds.includes(flow.id));
        case actionTypes.UPDATE_FLOW:
            return state.map(flow => {
                return flow.id === action.flowId ? { ...flow, name : action.name } : flow;
            });
        default:
            return state;
    }
};

export const apisReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_APIS:
            return action.value;
        case actionTypes.ADD_API:
            return [...state, action.value];
        case actionTypes.DELETE_API:
            return state.filter(api => api.id !== action.apiId);
        case actionTypes.DELETE_APIS:
            return state.filter(api => !action.apiIds.includes(api.id));
        case actionTypes.UPDATE_API:
            return state.map(api => {
                return api.id === action.apiId ? { ...api, name : action.name } : api;
            });
        default:
            return state;
    }
};

export const sourcesReducer = (state, action) => {
    switch (action.type) {
        case actionTypes.SET_SOURCES:
            return action.value;
        case actionTypes.ADD_SOURCE:
            return [...state, action.value];
        case actionTypes.DELETE_SOURCE:
            return state.filter(source => source.id !== action.sourceId);
        case actionTypes.DELETE_SOURCES:
            return state.filter(source => !action.sourceIds.includes(source.id));
        default:
            return state;
    }
};