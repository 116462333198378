import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CardHeader,
    Avatar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { StatusBullet } from "../../../../Components";
import ApiForm from "../ApiForm";
import {deleteApi, withErrorHandlingAndLoading} from "../../../../constants/functions";
import {useStateValue} from "../../../../StateContext";
import {actionTypes} from "../../../../StateContext/types";
import Loading from "../../../../Components/Loading/Loading";
import Tooltip from "@material-ui/core/Tooltip";


const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '100%',
        margin: '0 auto',
    },
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: '100%',
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center',
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1),
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
}));

const ApiCard = ({ className, product, handleAlert, ...rest }) => {
    const classes = useStyles();
    const { apisDispatch} = useStateValue([]);
    const [loading , setLoading] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const handleOpenEdit = () => {
        setOpenEdit(true);
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    const truncateString = (str, num) => {
        return str.length > num ? str.slice(0, num) + '...' : str;
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard');
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    };

    const handleDeleteContinue = withErrorHandlingAndLoading(async () => {
        await deleteApi(product.id);
        await apisDispatch({
            type : actionTypes.DELETE_API ,
            apiId : product.id
        })
        handleAlert(true , 'API deleted successfully');
    }, setLoading , handleAlert);

    return (
        <>
            <Card {...rest} className={clsx(classes.root, className)}>
                <CardHeader
                    avatar={<Avatar aria-label="recipe" className={classes.avatar}>{product.modelName.charAt(0).toUpperCase()}</Avatar>}
                    action={
                        <>
                            <IconButton aria-label="edit" onClick={handleOpenEdit}>
                                <EditIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={handleOpenDelete}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    }
                    title={product.modelName}
                    subheader={product.id}
                />
                <CardContent >
                    <div className={classes.imageContainer}>
                        <img alt="Product" className={classes.image} src="/images/products/product_2.png" />
                    </div>
                    <Typography align="center" gutterBottom variant="h4">
                        {product.name}
                    </Typography>
                </CardContent>
                <Divider />
                <CardActions>
                    <Grid container justify="space-between">
                        <Grid className={classes.statsItem} item>
                            <LinkIcon className={classes.statsIcon} />
                            <Tooltip title={product.host}>
                                <Typography variant="body2" color="textSecondary">
                                    {truncateString(product.host, 20)}
                                </Typography>
                            </Tooltip>
                            <IconButton onClick={() => copyToClipboard(product.host)}>
                                <FileCopyIcon />
                            </IconButton>
                        </Grid>
                        <Grid className={classes.statsItem} item>
                            <StatusBullet color="success" size="sm" />
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>


            <Dialog open={openDelete} onClose={handleCloseDelete} maxWidth="sm">
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this API?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteContinue} color="primary" size="small">
                        Yes
                    </Button>
                    <Button onClick={handleCloseDelete} color="primary" size="small">
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEdit} onClose={handleCloseEdit} fullWidth maxWidth="sm">
                <DialogTitle>Edit API</DialogTitle>
                <DialogContent dividers>
                    <ApiForm initialValues={product} isEditMode={true} handleAlert={handleAlert} onClose={handleCloseEdit} />
                </DialogContent>
            </Dialog>
        </>
    );
};

ApiCard.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired,
    handleAlert: PropTypes.func.isRequired
};

export default memo(ApiCard);
