import React, { useState } from "react";
import { useStateValue } from "../../../../StateContext";
import { updateNotification, withErrorHandlingAndLoading } from "../../../../constants/functions";
import { actionTypes } from "../../../../StateContext/types";
import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
} from "@material-ui/core";
import { Drafts as MarkEmailReadIcon } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

export const NotificationMenu = ({ setLoading, handleAlert }) => {
    const { notificationsState, notificationsDispatch } = useStateValue();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(null);

    const handleNotificationClick = withErrorHandlingAndLoading(async (item) => {
        if (!item.seen) {
            await updateNotification(item.id);
            notificationsDispatch({
                type: actionTypes.UPDATE_NOTIFICATION,
                id: item.id,
            });
        }
        setSelectedNotification(item);
        setDialogOpen(true);
    }, setLoading, handleAlert);

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedNotification(null);
    };

    const handleReadAll = withErrorHandlingAndLoading(async () => {
        const unreadNotifications = notificationsState.filter(noti => !noti.seen);
        for (const noti of unreadNotifications) {
            await updateNotification(noti.id);
        }
        notificationsDispatch({
            type: actionTypes.MARK_ALL_AS_READ,
        });
    }, setLoading, handleAlert);

    return (
        <>
            <List style={{ padding: 10, minWidth: 200 , maxWidth: 300 }}>
                <ListItem>
                    <Typography variant="h6" style={{ flexGrow: 1 }}>
                        Notifications
                    </Typography>
                    <Tooltip title={'Mark all as read'}>
                        <IconButton color="primary" onClick={handleReadAll}>
                            <MarkEmailReadIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>

                {notificationsState.length > 0 ? (
                    notificationsState.map((item) => (
                        <ListItem button style={{ minWidth: 200, margin: 5, borderRadius: 5 }}
                                  selected={!item.seen} key={item.id} alignItems="flex-start"
                                  onClick={async () => { await handleNotificationClick(item) }}
                        >
                            <ListItemAvatar>
                                <Avatar alt="Notification Icon" src="/static/images/avatar/1.jpg" />
                            </ListItemAvatar>
                            <ListItemText primary={item.typeN}
                                          secondary={
                                              <>
                                                  <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                                                      {item.message}
                                                  </Typography>
                                              </>
                                          }
                            />
                        </ListItem>
                    ))
                ) : (
                    <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt="No Notifications" src="/static/images/avatar/1.jpg" />
                        </ListItemAvatar>
                        <ListItemText
                            primary="Notification"
                            secondary={
                                <>
                                    <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                                        {'There are no notifications ...'}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                )}
            </List>

            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Notification Details</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {selectedNotification && (
                            <>
                                <Typography variant="h6">{selectedNotification.typeN}</Typography>
                                <Typography variant="body2">{selectedNotification.message}</Typography>
                            </>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
