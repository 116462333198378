import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MessageAlert from "../../Components/Alert";
import { resetPasswordWithToken } from "../../constants/auth";
import { handleRedirectToLogin, withErrorHandlingAndLoading } from "../../constants/functions";
import PasswordForm from "./PasswordForm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { RequestTokenForm } from "./RequestTokenForm";
import {makeStyles} from "@material-ui/core/styles"; // Assuming this component exists

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: 40,
        width: '100%',
        maxWidth: 600,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    }
}));

const PasswordReset = () => {
    const location = useLocation();
    const classes = useStyles();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: "", severity: "info" });
    const [resetDone, setResetDone] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false); // State for controlling the request token dialog

    // Handle alert notifications
    const handleAlert = useCallback((open, message, severity) => {
        setAlert({ open, message, severity });
    }, []);

    const handleClose = useCallback(() => setAlert((prevState) => ({ ...prevState, open: false })), []);

    // Handle password reset logic
    const handleResetPassword = withErrorHandlingAndLoading(async (newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            handleAlert(true, "Passwords do not match", "error");
            return;
        }
        try {
            await resetPasswordWithToken(token, newPassword);
            handleAlert(true, "Password successfully reset.", "success");
            setResetDone(true);
        } catch (error) {
            console.error(error);

            // Handle token expiration specifically
            if (error.message === "Token expired, please sign in again" || error.message === "Invalid token.") {
                handleAlert(true, "Your link has expired. Please request a new one.", "error");
                setDialogOpen(true);  // Open dialog for token request
            } else {
                handleAlert(true, "An error occurred while resetting your password.", "error");
            }
        }
    }, setLoading, handleAlert);

    return (
        <Container component="main" maxWidth="xs">

            <CssBaseline />

            <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity} />

            {resetDone ? (
                <div className={classes.paper}>
                    <Typography variant="subtitle1">
                        Password successfully reset. You may now log in.
                    </Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        disabled={loading}
                        style={{
                            marginTop: "20px",
                            backgroundColor: "#11b276",
                            color: "#fff",
                        }}
                        onClick={handleRedirectToLogin}
                    >
                        Go to login page
                    </Button>
                </div>
            ) : (
                <PasswordForm title={resetDone ? "Success" : "Set New Password"} onSubmit={handleResetPassword} loading={loading} />
            )}

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <RequestTokenForm isTokenExpired passwordReset />
                </DialogContent>
            </Dialog>
        </Container>
    );
};

export default PasswordReset;
