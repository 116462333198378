import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Avatar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import InfoIcon from '@material-ui/icons/Info'; // Add EditIcon
import { StatusBullet } from '../../../../Components';
import {useStateValue} from "../../../../StateContext";
import {actionTypes} from "../../../../StateContext/types";
import { withErrorHandlingAndLoading} from "../../../../constants/functions";
import Loading from "../../../../Components/Loading/Loading";
import Tooltip from "@material-ui/core/Tooltip";
import {deleteConnector} from "../../../../constants/connectors";


const useStyles = makeStyles(theme => ({
    status: {
        marginRight: theme.spacing(1),
    },
}));

const MAX_NAME_LENGTH = 25;

const truncateName = (name) => {
    if (name.length > MAX_NAME_LENGTH) {
        return name.substring(0, MAX_NAME_LENGTH) + '...';
    }
    return name;
};

const ConnectorCard = ({ name, id , status, config , handleAlert, openForm }) => {

    const classes = useStyles();
    const [openDelete, setOpenDelete] = useState(false);
    const {connectorInfoDispatch} = useStateValue();
    const [loading, setLaoding] = useState(false);
    const { connectorsDispatch } = useStateValue();

    const handleOpenDelete = () => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };


    const handleDeleteContinue = withErrorHandlingAndLoading(async () => {
        await deleteConnector(id);
        await connectorsDispatch({ type: actionTypes.DELETE_CONNECTOR, value: id });
        handleCloseDelete();
    },setLaoding,handleAlert);


    const handleUpdate = async () => {
        try {
            await connectorInfoDispatch({ type: actionTypes.SET_CONNECTOR_INFO, value: {...config, name : name} });
            openForm('INFO');
        } catch (error) {
            console.error('Error updating connector:', error);
        }
    };

    const getImageInfo = () => {
        let imageSrc, classType;

        switch (config.connectorClass) {
            case 'io.confluent.connect.elasticsearch.ElasticsearchSinkConnector':
                imageSrc = '/images/products/product_3.jpg';
                classType = 'Elasticsearch';
                break;
            case 'io.confluent.connect.jdbc.JdbcSinkConnector':
                imageSrc = '/images/products/product_8.png';
                classType = 'JDBC';
                break;
            case 'com.thingwings.MqttSourceConnector':
                imageSrc = '/images/products/product_1.png';
                classType = 'MQTT';
                break;
            default:
                imageSrc = '/images/products/product_7.png';
                classType = 'Default';
                break;
        }

        return { imageSrc, classType };
    };

    const { imageSrc, classType } = getImageInfo();

    return (
        <>
            <Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <CardContent style={{ flex: 1 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Tooltip title={name.length > MAX_NAME_LENGTH ? name : ''} arrow>
                                <Typography variant="h6">
                                    {truncateName(name)}
                                </Typography>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" justifyContent="flex-end">
                                <Grid item>
                                    <IconButton aria-label="info" onClick={handleUpdate}>
                                        <Tooltip title={'Info'}>
                                            <InfoIcon color={"primary"} />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton aria-label="delete" onClick={handleOpenDelete}>
                                        <Tooltip title={'Delete'}>
                                            <DeleteIcon/>
                                        </Tooltip>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                        <Grid item>
                            <Avatar alt="Product" src={imageSrc} />
                        </Grid>
                    </Grid>
                    <Typography align="center" variant="body2">
                        {classType}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <div>
                                <Typography display="inline" variant="body2">
                                    {status.type.toUpperCase()}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item>
                            <div>
                                <StatusBullet
                                    className={classes.status}
                                    color={status.connector?.state === 'FAILED' ? 'warning' : 'success'}
                                    size="sm"
                                />
                                <Typography display="inline" variant="body2">
                                    {status.connector?.state}
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>

            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this connector?
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={ handleDeleteContinue } color="primary" size="small">
                        Yes
                    </Button>
                    <Button disabled={loading} onClick={handleCloseDelete} color="primary" size="small">
                        No
                    </Button>

                    {loading && <Loading size={12}/>}

                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConnectorCard;
