import React ,{useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select, DialogTitle, DialogContent, Dialog,
} from '@material-ui/core';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {addTopic, withErrorHandlingAndLoading} from "../../constants/functions";
import Box from "@material-ui/core/Box";
import { actionTypes } from "../../StateContext/types";
import Loading from "../../Components/Loading/Loading";
import {DialogHandler} from "../../Components";

const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column',
    },
    formControl: {
        minWidth: 120,
        margin: theme.spacing(1),
    },
    errorText: {
        color: 'red',
        margin: theme.spacing(1, 0),
    },
}));

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Field required')
        .matches(/^[^_]*$/, 'Name cannot contain underscores'),
    partitions: Yup.number().required('Field required'),
    replications: Yup.number().required('Field required'),
});

const TopicForm = ({ dialogType, closeDialog, handleAlert, kafkaTopicsDispatch }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const sendRequest = withErrorHandlingAndLoading(async (values) => {
        const response = await addTopic(values);
        kafkaTopicsDispatch({
            type: actionTypes.ADD_TOPIC,
            topic: response.topic
        });
        closeDialog(response.topic);
    }, setLoading, handleAlert);

    return (
        <DialogHandler  maxWidth={'sm'} title={'Add Topic'} onClose={closeDialog} open={dialogType === 0}>
            <Formik
                initialValues={{
                    name: '',
                    partitions: 1,
                    replications: 1,
                }}
                validationSchema={validationSchema}
                onSubmit={sendRequest}
            >
                {({ isSubmitting }) => (
                    <Form>
                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <Field
                                as={TextField}
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                required
                                variant="outlined"
                                error={Boolean(<ErrorMessage name="name" />)}
                                helperText={<ErrorMessage name="name" component="div" className={classes.errorText} />}
                            />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="partitions-select"># of partitions</InputLabel>
                            <Field
                                as={Select}
                                native
                                label="# of partitions"
                                name="partitions"
                                inputProps={{ id: 'partitions-select' }}
                            >
                                {[1, 2, 3, 4].map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="partitions" component="div" className={classes.errorText} />
                        </FormControl>

                        <FormControl variant="outlined" fullWidth className={classes.formControl}>
                            <InputLabel htmlFor="replications-select">Replication factor</InputLabel>
                            <Field
                                as={Select}
                                native
                                label="Replication factor"
                                name="replications"
                                inputProps={{ id: 'replications-select' }}
                            >
                                {[1, 2, 3, 4, 5].map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                            <ErrorMessage name="replications" component="div" className={classes.errorText} />
                        </FormControl>

                        <Box display="flex" justifyContent="flex-end">
                            <Button
                                variant="contained"
                                size="medium"
                                color="primary"
                                type="submit"
                                disabled={loading}
                            >
                                Add
                            </Button>
                            {loading && <Loading size={24}/>}
                        </Box>
                    </Form>
                )}
            </Formik>
        </DialogHandler>
    );
};

export default TopicForm;
