import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { IconButton, Grid, Typography, Divider, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { ConnectorCard, ConnectorForm, ConfigForm } from './components';
import { withErrorHandlingAndLoading } from '../../constants/functions';
import { useStateValue } from '../../StateContext';
import { actionTypes } from '../../StateContext/types';
import Loading from "../../Components/Loading/Loading";
import MessageAlert from "../../Components/Alert";
import { ActionsToolbar } from "../../Components";
import { fetchAllConnectors } from "../../constants/connectors";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));

const ConnectorsList = ({ history }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const [openForm, setOpenForm] = useState('');
    const [alert, setAlert] = useState({
        open: false, message: "", severity: "info"
    });
    const { connectorsState, connectorsDispatch, connectorInfoState } = useStateValue();
    const [searchQuery, setSearchQuery] = useState('');

    const [currentPage, setCurrentPage] = useState(1); // Current page
    const itemsPerPage = 8; // Items per page

    // Handle open form
    const handleOpenForm = (type) => {
        setOpenForm(type);
    };

    // Handle close form
    const handleCloseForm = () => {
        setOpenForm('');
        //setCurrentPage((Math.ceil(filteredData.length / itemsPerPage)) )
    };

    // Handle alert
    const handleAlert = (open, message, severity) => {
        setAlert({ open, message, severity });
    };

    const handleAlertClose = () => {
        setAlert(prevState => ({ ...prevState, open: false }));
    };

    // Fetch connectors data
    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const data = await fetchAllConnectors();
            connectorsDispatch({ type: actionTypes.SET_CONNECTORS, value: data });
        }, setLoading, handleAlert);

        fetchData();
    }, [connectorsDispatch]);

    // Handle search change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filtered data based on search query
    const filteredData = connectorsState.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Paginate filtered data
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    // Change page
    const handlePageChange = (direction) => {
        if (direction === 'next' && currentPage < Math.ceil(filteredData.length / itemsPerPage)) {
            setCurrentPage(prevPage => prevPage + 1);
        } else if (direction === 'prev' && currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    return (
        <>
            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleAlertClose}
                severity={alert.severity}
            />

            <ActionsToolbar
                title={'Connector'}
                handleSearch={handleSearchChange}
                openForm={handleOpenForm}
            />

            {loading ? <Loading /> :
                <Grid container spacing={3}>
                    <Grid item xs={12}><Divider /> </Grid>
                    {currentItems.map(Connector => (
                        <Grid item key={Connector.id} xs={12} sm={6} md={4} lg={3}>
                            <ConnectorCard
                                history={history}
                                name={Connector.name}
                                id={Connector.id}
                                status={Connector.status}
                                config={Connector.config}
                                handleAlert={handleAlert}
                                openForm={handleOpenForm}
                            />
                        </Grid>
                    ))}
                </Grid>
            }

            <div className={classes.pagination}>
                <IconButton onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
                    <ChevronLeftIcon />
                </IconButton>
                <Typography variant="caption">{`${currentPage} of ${Math.ceil(filteredData.length / itemsPerPage)}`}</Typography>
                <IconButton onClick={() => handlePageChange('next')} disabled={currentPage === Math.ceil(filteredData.length / itemsPerPage)}>
                    <ChevronRightIcon />
                </IconButton>
            </div>

            <Dialog open={Boolean(openForm)} onClose={handleCloseForm}>
                {openForm === 'ADD' ?
                    <ConnectorForm
                        handleAlert={handleAlert}
                        handleCloseForm={handleCloseForm}
                    /> :
                    openForm === 'INFO' ? <>
                        <DialogTitle disableTypography={false}>{connectorInfoState.name}</DialogTitle>
                        <DialogContent dividers>
                            <ConfigForm initialValues={connectorInfoState} />
                        </DialogContent>
                    </> : null
                }
            </Dialog>

        </>
    );
};

export default ConnectorsList;
