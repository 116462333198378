import React, { useCallback, useState } from "react";
import { withErrorHandlingAndLoading } from "../../constants/functions";
import { sendResetEmail } from "../../constants/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Loading from "../../Components/Loading";
import { makeStyles } from "@material-ui/core/styles";
import MessageAlert from "../../Components/Alert";

const useStyles = makeStyles((theme) => ({
    form: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export const RequestTokenForm = ({isTokenExpired, passwordReset, userActivation,}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        message: "",
        severity: "info",
    });
    const [emailSent, setEmailSent] = useState(false);
    const [email, setEmail] = useState(""); // Store the email for resending

    const handleAlert = useCallback((open, message, severity) => {
        setAlert({ open, message, severity });
    }, []);
    const handleClose = useCallback(
        () => setAlert((prev) => ({ ...prev, open: false })),
        []
    );

    const emailSender = withErrorHandlingAndLoading(
        async (values) => {
            let type = "activation";
            if (passwordReset) type = "password-reset";
            if (isTokenExpired) type = userActivation ? "token-reset" : "password-reset";

            await sendResetEmail(values.email, type);
            setEmail(values.email); // Store email for resending
            setEmailSent(true);
            handleAlert(
                true,
                "Please check your email for further instructions.",
                "success"
            );
        },
        setLoading,
        handleAlert
    );

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email is required"),
        }),
        onSubmit: async (values) => {
            await emailSender(values);
        },
    });

    // Determine the title and description based on the context
    const getTitle = () => {
        if (passwordReset) return "Password Reset";
        if (isTokenExpired && userActivation) return "Request New Verification Link";
        return "Account Activation";
    };

    const getDescription = () => {
        if (passwordReset && !emailSent)
            return "Enter your email address to receive a password reset link.";
        if (isTokenExpired && userActivation)
            return "Your verification link has expired. Enter your email to receive a new activation link.";
        return "";
    };

    return (
        <>
            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleClose}
                severity={alert.severity}
            />

            <Typography variant="h6">{getTitle()}</Typography>
            <Typography variant="caption">{getDescription()}</Typography>

            {isTokenExpired && !emailSent && (
                <Typography color="error" style={{ marginTop: 20 }}>
                    Your link has expired. Please request a new one.
                </Typography>
            )}

            <form onSubmit={formik.handleSubmit} className={classes.form}>
                {!emailSent ? (
                    <>
                        <TextField
                            type="email"
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            size="small"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            disabled={loading || !formik.isValid}
                        >
                            {loading ? "Sending..." : "Send Email"}
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography variant="body1" style={{ marginTop: 20 }}>
                            Email sent to: <strong>{email}</strong>
                        </Typography>
                        <Typography style={{ marginTop: "10px", color: "#555" }}>
                            Didn’t receive the email? Check your spam folder or request a new
                            link.
                        </Typography>
                        <Button
                            onClick={() => emailSender({ email })}
                            fullWidth
                            variant="contained"
                            style={{
                                marginTop: "20px",
                                backgroundColor: "#11b276",
                                color: "#fff",
                            }}
                            disabled={loading}
                        >
                            {loading ? "Resending..." : "Resend Email"}
                        </Button>
                    </>
                )}

                {loading && <Loading />}
            </form>
        </>
    );
};
