import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography, Avatar, IconButton, CircularProgress } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '50%',
    padding: theme.spacing(2),
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    fontWeight: 700
  },
  icon: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  difference: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  icondown: {
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(0.5)
  },
  icondowntype: {
    color: theme.palette.warning.dark
  },
  caption: {
    marginRight: theme.spacing(0.5)
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(0.5)
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

const TotalConnectors = ({
                           history,
                           loading,
                           sourceConnectorsCount,
                           sinkConnectorsCount,
                           httpConnectorsCount,
                           runningConnectorsCount,
                           failedConnectorsCount
                         }) => {
  const classes = useStyles();

  const goto = () => {
    history.push('connectors');
  };

  return (
      <div className={classes.root} onClick={goto}>
            <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
            >
              CONNECTIONS
              <IconButton onClick={goto}>
                <ArrowForwardIcon className={classes.icon} />
              </IconButton>
            </Typography>
           { loading ? (
                 <div className={classes.loadingContainer}>
                   <CircularProgress />
                 </div>
             ) : <>
              <Grid container spacing={2}>

                <Grid xs={3} item>
                  <Typography variant="h4">{sinkConnectorsCount + sourceConnectorsCount}</Typography>
                </Grid>

                <Grid xs={5} item>


                  <div className={classes.difference}>
                    <Typography className={classes.differenceValue} variant="body2">
                      {`${runningConnectorsCount} Running` }
                    </Typography>
                    <ArrowUpwardIcon className={classes.differenceIcon} />
                  </div>

                  <div className={classes.difference}>
                    <Typography className={classes.icondown} variant="body2">
                      {`${failedConnectorsCount} Failed` }
                    </Typography>
                    <ArrowDownwardIcon className={classes.icondowntype} />
                  </div>

                </Grid>

                <Grid xs={4} item>
                  <Avatar className={classes.avatar}>
                    <SettingsInputHdmiIcon />
                  </Avatar>
                </Grid>

              </Grid>
            </>
           }
      </div>
  );
};

TotalConnectors.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  sourceConnectorsCount: PropTypes.number.isRequired,
  sinkConnectorsCount: PropTypes.number.isRequired,
  httpConnectorsCount: PropTypes.number.isRequired,
  runningConnectorsCount: PropTypes.number.isRequired,
  failedConnectorsCount: PropTypes.number.isRequired
};

export default TotalConnectors;
