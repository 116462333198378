import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Divider,
    Button,
    Typography,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Custom styles for DialogTitle, DialogContent, and DialogActions
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogTitle: {
        padding: theme.spacing(2),
    },
    dialogContent: {
        padding: theme.spacing(2),
    },
    dialogActions: {
        margin: 0,
        padding: theme.spacing(1),
    },
});

const DialogTitleWithClose = withStyles(styles)(({ classes, onClose, children }) => (
    <DialogTitle disableTypography className={classes.root}>
        <Typography variant="h6">{children}</Typography>
        {onClose && (
            <IconButton edge="end" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        )}
    </DialogTitle>
));

const DialogContentWithStyle = withStyles(styles)(({ classes, children }) => (
    <DialogContent dividers className={classes.dialogContent}>
        {children}
    </DialogContent>
));

const DialogActionsWithStyle = withStyles(styles)(({ classes, children }) => (
    <DialogActions className={classes.dialogActions}>{children}</DialogActions>
));

const DialogHandler = ({open,maxWidth, onClose, title, contentText, onConfirm, confirmText, children }) => {
    return (
        <Dialog open={open} fullWidth maxWidth={maxWidth} aria-labelledby="generic-dialog-title">
            <DialogTitleWithClose id="generic-dialog-title" onClose={onClose}>
                {title}
            </DialogTitleWithClose>
            {
                contentText &&
                <DialogContentText>{contentText}</DialogContentText>
            }
            <DialogContentWithStyle>
                {children}
            </DialogContentWithStyle>
            <DialogActionsWithStyle>
                {onConfirm && (
                    <>
                        <Button onClick={onConfirm} color="primary">
                            {confirmText || "Confirm"}
                        </Button>
                    </>
                )}
            </DialogActionsWithStyle>
        </Dialog>
    );
};

export default DialogHandler;
