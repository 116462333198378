import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link, useMediaQuery } from '@material-ui/core';
import { withErrorHandlingAndLoading } from "../../../../constants/functions";
import { listInvoices } from "../../../../constants/stripe";
import Loading from "../../../../Components/Loading";

const InvoiceItem = ({ invoice, isMobile }) => {

    const formatCurrency = (amount, currency) => {
        return `${(amount / 100).toFixed(2)} ${currency.toUpperCase()}`;
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const getFrequency = (invoice) => {
        const plan = invoice.lines.data.find(line => line.plan && line.plan.interval);
        return plan ? plan.plan.interval.charAt(0).toUpperCase() + plan.plan.interval.slice(1) : '—';
    };

    return (
        <TableRow>
            <TableCell>{formatCurrency(invoice.amount_paid, invoice.currency)}</TableCell>
            <TableCell>{invoice.currency.toUpperCase()}</TableCell>
            <TableCell>{invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}</TableCell>
            <TableCell>{getFrequency(invoice)}</TableCell>
            <TableCell>{invoice.number}</TableCell>
            <TableCell>{formatDate(invoice.created)}</TableCell>
            {!isMobile && (
                <TableCell>
                    <Link href={invoice.invoice_pdf} target="_blank" rel="noopener">Download PDF</Link>
                </TableCell>
            )}
        </TableRow>
    );
};

const InvoiceList = ({ user, handleAlert }) => {
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)'); // Set mobile breakpoint

    useEffect(() => {
        const showInvoices = withErrorHandlingAndLoading(async () => {
            const resp = await listInvoices(user.CusId);
            setInvoices(resp);
        }, setLoading, handleAlert);

        showInvoices();
    }, []);

    return (
        <Paper style={{ padding: '16px', marginBottom: '16px' }}>
            <TableContainer style={{ overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Amount Paid</TableCell>
                            <TableCell>Currency</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Frequency</TableCell>
                            <TableCell>Invoice Number</TableCell>
                            <TableCell>Created</TableCell>
                            {!isMobile && <TableCell>Actions</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            invoices.map(invoice => (
                                <InvoiceItem key={invoice.id} invoice={invoice} isMobile={isMobile} />
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {loading && <Loading size={20} />}
        </Paper>
    );
};

export default InvoiceList;
