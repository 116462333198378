import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    TextField,
    Grid,
} from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createFilterOptions } from '@material-ui/lab';
import { fetchThings, fetchAclRecords, withErrorHandlingAndLoading } from '../../../../constants/functions';
import { actionTypes } from '../../../../StateContext/types';
import { useStateValue } from '../../../../StateContext';
import Loading from "../../../../Components/Loading/Loading";
import ThingForm from "../../../Acls/components/ThingForm";
import Typography from "@material-ui/core/Typography";
import {PermissionForm} from "../../../Acls/components/PermissionForm";


const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    inputContainer: {
        width: '100%',
        maxWidth: 400,
        marginTop: 20,
    },
}));

const Thing = ({ values, setValues, handleAlert, setLoading, loading }) => {
    const classes = useStyles();
    const { thingsState, thingsDispatch } = useStateValue();
    const [permissions, setPermissions] = useState([]);
    const [dialogState, setDialogState] = useState({ add: false, formType: '' });
    const [aclLoading, setAclLoading] = useState(false);

    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const thingsResponse = await fetchThings();
            await thingsDispatch({
                type: actionTypes.SET_THINGS,
                value: thingsResponse,
            });
        }, setLoading, handleAlert);

        fetchData();
    }, [thingsDispatch, setLoading, handleAlert]);

    const fetchPermissions = withErrorHandlingAndLoading(async () => {
        if (values.thing?.thing_id) {
            const response = await fetchAclRecords(values.thing.thing_id);
            setPermissions(response);
        }
    },  setAclLoading, handleAlert);

    useEffect(() => {
        fetchPermissions();
    }, [values.thing]);

    const handleThingChange = (event, newValue) => {
        if(!newValue){
            setPermissions([]);
        }
        else if (newValue == 'Add New Thing') {
            setDialogState({ add: true, formType: 'thing' });
        } else {
            setValues((prevValues) => ({
                ...prevValues,
                thing: {
                    ...newValue,
                    name: newValue?.name,
                },
            }));
        }
    };

    const handlePermissionChange = (event, newValue) => {
        if (newValue === 'Add New Permission') {
            setDialogState({ add: true, formType: 'permission' });
        } else {
            setValues((prevValues) => ({
                ...prevValues,
                thing: {
                    ...prevValues.thing,
                    permission: newValue,
                },
            }));
        }
    };

    const handleCloseDialog = (addedItem) => {
        //setNewThing(addedThing);
        dialogState.formType === 'thing' &&
        setValues((prevValues) => ({
            ...prevValues,
            thing: addedItem,
        }));

        dialogState.formType === 'permission' &&
        setValues((prevValues) => ({
            ...prevValues,
            thing: {...values.thing, permission : addedItem },
        }));

        setDialogState({ add: false, formType: '' });
    };

    return (
        <div className={classes.root}>
            <Grid container justifyContent="center">
                <Grid item xs={12} md={6}>
                    <Typography variant={'h6'}>
                        Thing & Permission
                    </Typography>

                    <div className={classes.inputContainer}>
                        <Autocomplete
                            loading={loading}
                            size="small"
                            value={values.thing}
                            onChange={handleThingChange}
                            options={['Add New Thing', ...thingsState]}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                            renderInput={(params) => (
                                <TextField {...params} label="Thing" variant="outlined" fullWidth />
                            )}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                            }}
                        />
                    </div>
                    <div className={classes.inputContainer}>
                        <Autocomplete
                            loading={aclLoading}
                            size="small"
                            value={values.thing.permission}
                            onChange={handlePermissionChange}
                            options={['Add New Permission', ...permissions]}
                            getOptionLabel={(option) => (typeof option === 'string' ? option : option.topic)}
                            renderInput={(params) => (
                                <TextField {...params} label="Permission" variant="outlined" fullWidth />
                            )}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                return filtered;
                            }}
                            clearOnBlur
                        />
                    </div>
                </Grid>
            </Grid>
                {dialogState.formType === 'thing' &&
                    <ThingForm
                        open={dialogState.add }
                        handleCloseNew={handleCloseDialog}
                        handleAlert={handleAlert}
                        thingName={values.thing.name}
                    />
                }
                { dialogState.formType === 'permission' &&
                    <PermissionForm
                        openDialog={dialogState}
                        handleCloseDialog={handleCloseDialog}
                        selectData={values.thing}
                        setLoading={setLoading}
                        handleAlert={handleAlert}
                        fetchDataAcl={fetchPermissions}
                    />
                }
        </div>
    );
};

Thing.propTypes = {
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    handleAlert: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};

export default Thing;
