import React, { useEffect, useState } from 'react';
import clsx from "clsx";
import { TextField, Grid, Card, CardContent, Button, CardHeader, CardActions, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import { withErrorHandlingAndLoading } from "../../../../constants/functions";
import Loading from "../../../../Components/Loading";
import { actionTypes } from "../../../../StateContext/types";
import { useStateValue } from "../../../../StateContext";
import AddressForm from '../Forms/AddressForm';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { listBillingAddress , deleteBillingAddress} from "../../../../constants/users";

const useStyles = makeStyles(() => ({
    root: {
        padding: '16px',
        margin: '16px 0',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    centeredMessage: {
        textAlign: 'center',
        marginTop: '16px',
    },
    headerActions: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addressCard: {
        padding: '10px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
}));

const BillingAddress = (props) => {
    const { className, handleAlert, ...rest } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { billingAddressesDispatch, billingAddressesState } = useStateValue();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false); // For delete confirmation
    const [addressData, setAddressData] = useState(null);
    const [addressToDelete, setAddressToDelete] = useState(null); // Store address to delete

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setAddressData(null); // Reset the form data after closing
    };

    const handleOpenDeleteDialog = (addressId) => {
        setAddressToDelete(addressId); // Store the address ID to be deleted
        setConfirmDeleteDialogOpen(true); // Open confirmation dialog
    };

    const handleCloseDeleteDialog = () => {
        setConfirmDeleteDialogOpen(false);
        setAddressToDelete(null); // Reset after closing dialog
    };

    const handleDeleteConfirmed = async () => {
        console.log("Delete address with ID:", addressToDelete);
        await deleteBillingAddress(addressToDelete)
        await billingAddressesDispatch({ type: actionTypes.DELETE_SOURCE,  addressId: addressToDelete });
        handleCloseDeleteDialog(); // Close the confirmation dialog after deletion
    };

    const handleEditAddress = (address) => {
        setAddressData(address); // Set the existing address data
        handleOpenDialog();
    };

    useEffect(() => {
        const getAddresses = withErrorHandlingAndLoading(async () => {
            const resp = await listBillingAddress();
            await billingAddressesDispatch({ type: actionTypes.SET_ADDRESSES, value: resp });
        }, setLoading, handleAlert);
        getAddresses();
    }, []);

    return (
        <>
            <Card sx={{ height: '100%' }} {...rest} className={clsx(classes.root, className)}>
                <CardHeader
                    title={
                        <div className={classes.headerActions}>
                            <span>Billing Address</span>
                            <Button variant="contained" color="primary" onClick={handleOpenDialog}>
                                Add Address
                            </Button>
                        </div>
                    }
                />
                <CardContent>
                    {
                        loading ? <Loading size={20}/> :
                            billingAddressesState?.length === 0 ? (
                                <Typography variant="body1" className={classes.centeredMessage}>
                                    No billing addresses available.
                                </Typography>
                            ) : (
                                <Grid container spacing={3}>
                                    {billingAddressesState?.map((address, index) => (
                                        <Grid style={{display:'flex', alignItems:"center"}} item >
                                            <Card variant="outlined" className={classes.addressCard}>
                                                <CardContent>
                                                    {/* Edit Button */}
                                                    <IconButton
                                                        style={{color:'blue'}}
                                                        aria-label="edit"
                                                        className={classes.editButton}
                                                        onClick={() => handleEditAddress(address)} // Pass address to edit
                                                    >
                                                        <EditIcon />
                                                    </IconButton>

                                                    {/* Delete Button */}
                                                    <IconButton
                                                        style={{color:'red'}}
                                                        aria-label="delete"
                                                        className={classes.deleteButton}
                                                        onClick={() => handleOpenDeleteDialog(address.id)} // Open delete confirmation
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>

                                                    <Typography align={"center"} variant="body1">{address.firstname} {address.lastname}</Typography>
                                                    <Typography align={"center"} variant="body2">{address.phone}</Typography>
                                                    <Typography align={"center"} variant="body2">{address.address_line1}</Typography>
                                                    <Typography align={"center"} variant="body2">{address.address_city}</Typography>
                                                    <Typography align={"center"} variant="body2"> {address.address_zip} {address.address_country}</Typography>

                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            )
                    }
                </CardContent>
            </Card>

            {/* Address Form for editing */}
            <AddressForm
                handleAlert={handleAlert}
                open={dialogOpen}
                onClose={handleCloseDialog}
                addressData={addressData} // Pass the address data for editing
            />

            {/* Confirmation Dialog for delete */}
            <Dialog
                open={confirmDeleteDialogOpen}
                onClose={handleCloseDeleteDialog}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this address?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirmed} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BillingAddress;
