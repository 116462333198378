import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import {ApiForm} from "./components";
import React from "react";
import {ConnectorForm} from "../ConnectorsList/components";

export const ApiDialog = ({dialogOpen, handleDialogClose, handleAlert,  setValues,  topic}) => {

    return <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle id="form-dialog-title">API Information</DialogTitle>
        <DialogContent dividers>
            <ApiForm
                handleAlert={handleAlert}
                onClose={handleDialogClose}
                topic = {topic}
                setValues={setValues}
            />
        </DialogContent>
    </Dialog>;
}