import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {useStateValue} from "../../StateContext";
import {
    deleteDatabase,
    fetchDatabases,
    withErrorHandlingAndLoading
} from "../../constants/functions";
import {actionTypes} from "../../StateContext/types";
import Loading from "../../Components/Loading/Loading";
import AddForm from './AddForm';
import TableContainer from "@material-ui/core/TableContainer";
import Toolbar from './Toolbar'
import {DialogContentText, Divider, Typography} from "@material-ui/core";
import {DialogHandler} from "../../Components"

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    padding: {
        padding: theme.spacing(1),
    },
    username: {
        padding: theme.spacing(1),
    },
    searchInputContainer: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        flexGrow: 1,
    },
    searchInput: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginBottom: theme.spacing(1),
        },
    },
    table: {
        minWidth: 650,
        [theme.breakpoints.down('xs')]: {
            minWidth: '100%',
            '& thead': {
                display: 'none',
            },
            '& tbody, & tr, & td': {
                display: 'block',
                width: '100%',
            },
            '& td': {
                textAlign: 'right',
                paddingLeft: '50%',
                position: 'relative',
            },
            '& td::before': {
                content: 'attr(data-label)',
                position: 'absolute',
                left: 0,
                width: '50%',
                paddingLeft: theme.spacing(2),
                fontWeight: 'bold',
                textAlign: 'left',
            },
        },
    },
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    fallbackMessage:{
        margin : 10
    }
}));

const Database = ({ handleAlert }) => {

    const classes = useStyles();

    const {databasesState, databasesDispatch} = useStateValue();
    const [selectedData, setSelectedData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [openNew, setOpenNew] = useState(false);

    const handleOpenAdd = () => {setOpenNew(true); closeMenu()};
    const handleOpenEdit = () => {setOpenNew(true); closeMenu()};
    const handleCloseNew = () => {
        selectedData && setSelectedData(null);
        setOpenNew(false);
    };

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => {setOpenDelete(true); closeMenu()}
    const handleCloseDelete = () => {setOpenDelete(false);}

    const fetchData = withErrorHandlingAndLoading(async () => {
        const response = await fetchDatabases();
        await databasesDispatch({
            type: actionTypes.SET_DATABASES,
            value: response
        });
    }, setLoading, handleAlert);
    const handleDelete = withErrorHandlingAndLoading(async () => {
        await deleteDatabase(selectedData.id);
        handleCloseDelete();
        await databasesDispatch({
            type: actionTypes.DELETE_DATABASE,
            id: selectedData.id
        });
    }, setLoading, handleAlert);

    useEffect(() => { fetchData() }, []);

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const filteredData = databasesState.filter((item) =>
        item.dbname.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const menuOptions = [
        {
            key: 'edit',
            label: 'Edit Database',
            onClick: handleOpenEdit
        },
        {
            key: 'delete',
            label: 'Delete Database',
            onClick: handleOpenDelete
        }
    ];
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleActionClick = (event,db) => {
        setSelectedData(anchorEl ? [] : db);
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const closeMenu = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Toolbar handleSearch={handleSearchChange} openForm={handleOpenAdd}/>

            <Card style={{padding:5}}>

                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
                    {menuOptions.map(option => (
                        <MenuItem key={option.key} onClick={option.onClick}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Menu>

                <Grid style={{ padding: 10 }} item xs={12}>

                    { loading ? <Loading size={20}/>  :
                        <TableContainer>
                            <Table className={classes.table} aria-label="responsive table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="center">Type</TableCell>
                                        <TableCell align="center">Username</TableCell>
                                        <TableCell align="center">URL</TableCell>
                                        <TableCell align="center">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.map((db) => (
                                        <TableRow key={db.id}>
                                            <TableCell align="center" data-label="Name">{db.dbname}</TableCell>
                                            <TableCell align="center" data-label="Type">{db.dbtype}</TableCell>
                                            <TableCell align="center" data-label="Username">{db.username}</TableCell>
                                            <TableCell align="center" data-label="URL">{db.url}</TableCell>
                                            <TableCell align="center" data-label="Actions">
                                                <IconButton edge="end" onClick={(e) => handleActionClick(e, db)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {
                                filteredData?.length === 0 && (
                                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Typography className={classes.fallbackMessage} variant="h6">
                                            No available databases
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </TableContainer>
                    }
                </Grid>
            </Card>

            <DialogHandler
                open={openDelete}
                onClose={handleCloseDelete}
                title="Confirm Deletion"
                contentText="Are you sure you want to delete this item?"
                onConfirm={handleDelete}
                confirmText="Delete"
            />


            <DialogHandler
                open={openNew}
                onClose={handleCloseNew}
                title="Database Information"
                contentText="Databases are used to store data received from Kafka server."
            >
                <AddForm db={selectedData} handleCloseNew={handleCloseNew} handleAlert={handleAlert} setLoading={setLoading} />
            </DialogHandler>

        </>
    );
}


export default Database;
