import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import { FlowForm } from "../../../Flows/FlowForm";
import Box from "@material-ui/core/Box";
import Loading from "../../../../Components/Loading";
import {IFrame} from "../../../Flows/IFrame";
import DialogHandler from "../../../../Components/DialogHandler";
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        borderRadius: 8,
    },
    connectorPaper: {
        padding: theme.spacing(2),
        margin: theme.spacing(2, 0),
        border: '1px solid #ccc',
        borderRadius: 8,
        backgroundColor: '#f9f9f9',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
    },
    title: {
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
    },
}));

const Flow = ({ history, handleAlert, setLoading, loading,  values, setValues }) => {
    const classes = useStyles();
    const [dialogState, setDialogState] = useState({ add: false, type: '' });
    const [viewFlow, setViewFlow] = useState(null); // State for viewing flow details
    const [isLoaded, setIsLoaded] = useState(false);
    const token = getToken();

    function handleIframeLoad() {
        setIsLoaded(true);
    }
    const addProcessFlow = (newFlow) => {

        setValues(
            prevState => ({
                ...prevState ,
                flowList :  [...prevState.flowList, newFlow]
            })
        )
        setDialogState({ add: false, type: '' }); // Reset dialog state
    };

    const handleAddFlow = () => {
        setDialogState({ add: true, type: '' });
    };

    const handleDialogClose = () => {
        setDialogState({ add: false, type: '' });
    };

    const handleViewFlow = (flow) => {
        setViewFlow(flow);
    };

    const handleViewClose = () => {
        setViewFlow(null);
    };

    return (
        <Grid justifyContent="center" container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" variant="h6">
                    Flow Information
                </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
                <Paper className={classes.connectorPaper} onClick={handleAddFlow}>
                    <Typography variant="subtitle1">Add New Flow</Typography>
                </Paper>
            </Grid>

            {/* Render Flow List */}
            {values.flowList.length > 0 && (
                <Grid
                    item
                    container
                    xs={12}
                    justifyContent={values.flowList.length === 1 ? 'center' : 'flex-start'}
                    spacing={2}
                >
                    {values.flowList.map((flow, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <Paper
                                className={classes.connectorPaper}
                                onClick={() => handleViewFlow(flow)}
                            >
                                <Typography variant="subtitle1">{flow.flowName}</Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}

            <FlowForm
                {...{
                    flowControl: dialogState,
                    setProgress: setLoading,
                    addProcessFlow,
                    handleAlert,
                    handleDialogClose,
                    progress: loading,
                    history,
                    isProcess: true,
                }}
            />

            {/* View Flow Dialog */}
            {viewFlow && (
                <DialogHandler open={Boolean(viewFlow)} maxWidth={"lg"} onClose={handleViewClose} title={`Flow: ${viewFlow.flowName}`}>
                    <Box display="flex" justifyContent="center">
                        {isLoaded ? <></> : <Loading/>} {/* Show Loading until iframe is loaded */}
                    </Box>

                    <IFrame containerName={viewFlow.containerName} token={token} onLoad={handleIframeLoad}/>
                </DialogHandler>
            )}
        </Grid>
    );
};

Flow.propTypes = {
    handleAlert: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
};

export default Flow;
