import React, { useEffect, useState } from 'react';
import { Grid, ListItem, ListItemAvatar, ListItemText, Avatar, CircularProgress, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { AddCircle, Edit, Delete, Star } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import visa from '../../../../../public/visa.jpeg';
import mastercard from '../../../../../public/mastercard.png';
import amex from '../../../../../public/americanexpress.png';
import {
    clientSecretPull,
    deleteMethod,
    methodsGet,
    performPayment,
    setDefaultMethod,
    updateMethod
} from "../../../../../constants/stripe";
import Loading from "../../../../../Components/Loading";
import { useStateValue } from "../../../../../StateContext";
import { actionTypes } from "../../../../../StateContext/types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/styles";
import { withErrorHandlingAndLoading } from "../../../../../constants/functions";
import {CardCvcElement, useElements} from "@stripe/react-stripe-js";
import PaymentForm from "./PaymentForm";

const icons = { visa, mastercard, amex };

const useStyles = makeStyles((theme) => ({
    box: {
        borderRadius: '12px',
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontSize: '0.75rem',
        padding: '2px 8px',
        display: 'inline-block'
    },
}));

export default function Methods({ onAdd, user,stripe, priceId, isSelectable, handleAlert }) {

    const { paymentMethodsState, paymentMethodsDispatch, formValuesState } = useStateValue();
    const [currentMethod, setCurrentMethod] = useState(null);
    const [loading, setLoading] = useState(false);
    const [defaultpm, setDefaultpm] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [methodToDelete, setMethodToDelete] = useState('');
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [newExpDate, setNewExpDate] = useState('');
    const [newCVC, setNewCVC] = useState('');
    const classes = useStyles();
    const history = useHistory();

    const elements = useElements();

    useEffect(() => {
        const retrieveMethods = async () => {
            try {
                setLoading(true);
                const data = await methodsGet(user.CusId);
                await paymentMethodsDispatch({ type: actionTypes.SET_METHODS, value: data.methods });
                setDefaultpm(data.default);
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };
        retrieveMethods();
    }, [user.CusId]);

    const handlePayment = async () => {
        if (!isSelectable || !currentMethod) return;
        try {
            setLoading(true);
            await performPayment(user.CusId, currentMethod, priceId);
            await history.push(`/plan`);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteMethod = withErrorHandlingAndLoading(async () => {
        try {
            await deleteMethod(user.CusId, methodToDelete);
            await paymentMethodsDispatch({ type: actionTypes.DELETE_METHOD, methodId: methodToDelete  });
            handleAlert(true, `Payment method deleted successfully.`, 'success');
        } catch (error) {
            handleAlert(true, `Error deleting payment method: ${error.message}`, 'error');
        }
    }, setLoading, handleAlert);

    const handleEdit = (methodId) => {
        setCurrentMethod(methodId);
        setOpenEditDialog(true);
    };

    const updateMethod = withErrorHandlingAndLoading(async () => {
        const clientSecret = await clientSecretPull(user.CusId); // Get the client secret for setup

        const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                id: currentMethod, // ID of the payment method you want to update
                card: elements.getElement(CardCvcElement), // CVC Element
                billing_details: {
                    address: {
                        line1: formValuesState.line1,
                        line2: formValuesState.line2,
                        city: formValuesState.city,
                        state: formValuesState.state,
                        postal_code: formValuesState.postal_code,
                        country: formValuesState.address_country,
                    },
                    name: `${formValuesState.firstname} ${formValuesState.lastname}`,
                    email: formValuesState.email,
                },
            }
        });

        if (error) {
            handleAlert(true, `Error updating payment method: ${error.message}`, 'error');
        } else if (setupIntent) {
            handleAlert(true, `Payment method updated successfully.`, 'success');
        }
    }, setLoading, handleAlert);

    const handleUpdate = async () => {
        if (!currentMethod || !newExpDate || !newCVC) return;
        try {
            setLoading(true);
            await updateMethod(currentMethod, user.CusId, newExpDate, newCVC);
            handleAlert("Payment method updated successfully!");
            setOpenEditDialog(false);
            setNewExpDate('');
            setNewCVC('');
        } catch (error) {
            console.log('Error updating payment method:', error);
            handleAlert("Error updating payment method.");
        } finally {
            setLoading(false);
        }
    };

    const handleSetDefault = withErrorHandlingAndLoading(async (methodId) => {
        try {
            await setDefaultMethod(user.CusId, methodId);
            await setDefaultpm(methodId);
        } catch (error) {
            console.log('Error setting payment method as default:', error);
        }
    }, setLoading, handleAlert);

    return (
        <Grid container direction="column" spacing={1}>
            <Grid item>
                <ListItem button onClick={onAdd}>
                    <ListItemAvatar>
                        <Avatar>
                            <AddCircle />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Add payment method" />
                </ListItem>
            </Grid>

            <Grid item container justifyContent="center" alignItems="center">
                {loading && <Loading size={20} />}
            </Grid>

            {paymentMethodsState?.map((method) => (
                <Grid item key={method.id}>
                    <ListItem
                        className={"flex items-center w-full h-10 py-1 px-2 shadow-lg m-2 justify-between rounded"}
                        button={isSelectable}
                        onClick={() => isSelectable && setCurrentMethod(method.id)}
                        disabled={isSelectable && method.id === currentMethod}
                    >
                        <ListItemAvatar>
                            <Avatar alt={method.card?.brand} src={icons[method.card?.brand]} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={`......${method.card.last4}`}
                            secondary={`Expires ${method.card.exp_month} ${method.card.exp_year}`}
                        />
                        {defaultpm === method.id && (
                            <Box className={classes.box}>
                                default
                            </Box>
                        )}

                        <div>
                           {/* <IconButton onClick={() => handleEdit(method.id)} aria-label="edit">
                                <Edit fontSize="small" />
                            </IconButton>*/}
                            <IconButton
                                onClick={() => {
                                    setMethodToDelete(method.id);
                                    setOpenDialog(true);
                                }} aria-label="delete">
                                <Delete fontSize="small" />
                            </IconButton>
                            <IconButton onClick={() => handleSetDefault(method.id)} aria-label="set default">
                                <Star fontSize="small" color={defaultpm === method.id ? 'primary' : 'inherit'} />
                            </IconButton>
                        </div>
                    </ListItem>
                </Grid>
            ))}

            {isSelectable && priceId && (
                <Button
                    onClick={handlePayment}
                    disabled={!currentMethod}
                    variant="contained"
                    color="primary"
                    style={{ alignSelf: 'flex-end', margin: '10px' }}
                >
                    Perform Payment
                </Button>
            )}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this payment method? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteMethod} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
                <DialogTitle>Edit Payment Method</DialogTitle>
                <DialogContent>
                   <PaymentForm type={"edit"}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEditDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={updateMethod} color="secondary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
