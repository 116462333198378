import React from 'react';
import {Main as MainLayout, Minimal as MinimalLayout} from './layouts';

import {
    Plan as PlanView,
    Payments as PaymentsView,
    Acls as AclsView,
    Swagger as SwaggerView,
    Nodered as NoderedView,
    NotFound as NotFoundView,
    ConnectorsList as ConnectorsView,
    Topics as TopicsView,
    Dashboard as DashboardView,
    Settings as SettingsView,
    ApiList as Api,
    Steps as StepsView,
    DbaseManager as DbaseManagerView,
    SignIn as SignTnView,
    UserList as UserListView,
    Account as AccountView,
    Flows as FlowsView,
    Library as LibraryView,
    SignUp as SignUpView,
    RequestResetToken as RequestResetTokenView ,
    ResetPassword as ResetPasswordView,
    UserActivation as UserActivationView
} from './views';

const Routes = () =>  [
    { path: '/signin', exact: true, component: SignTnView, layout: 'MinimalLayout' },
    { path: '/request-token', exact: true, component: RequestResetTokenView, layout: 'MinimalLayout' },
    { path: '/new-password', exact: true, component: ResetPasswordView, layout: 'MinimalLayout' },
    { path: '/activate-user/:token', exact: true, component: UserActivationView, layout: 'MinimalLayout' },
    { path: '/signup', exact: true, component: SignUpView, layout: 'MinimalLayout' },
    { path: '/', exact: true, component: DashboardView, layout: 'MainLayout', requiresAuth: true },
    { path: '/dashboard', exact: true, component: DashboardView, layout: 'MainLayout', requiresAuth: true },
    { path: '/things', exact: true, component: AclsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/nodered/:id', exact: true, component: NoderedView, layout: 'MainLayout', requiresAuth: true },
    { path: '/flows', exact: true, component: FlowsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/datastores', exact: true, component: DbaseManagerView, layout: 'MainLayout', requiresAuth: true },
    { path: '/steps', exact: true, component: StepsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/account', exact: true, component: AccountView, layout: 'MainLayout', requiresAuth: true },
    { path: '/plan', exact: true, component: PlanView, layout: 'MainLayout', requiresAuth: true },
    { path: '/plan/payments', exact: true, component: PaymentsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/settings', exact: true, component: SettingsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/topics', exact: true, component: TopicsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/ai-models', exact: true, component: Api, layout: 'MainLayout', requiresAuth: true },
    { path: '/downloads', exact: true, component: LibraryView, layout: 'MainLayout', requiresAuth: true },
    { path: '/connectors', exact: true, component: ConnectorsView, layout: 'MainLayout', requiresAuth: true },
    { path: '/swagger', exact: true, component: SwaggerView, layout: 'MainLayout', requiresAuth: true },
    { path: '/not-found', exact: true, component: NotFoundView, layout: 'MinimalLayout' },
    { path: '/users', exact: true, component: UserListView, layout: 'MainLayout', requiresAuth: true }
];

export default Routes;
