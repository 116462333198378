import React, {useState} from "react";
import {Container, Paper, Box, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import CustomizedSteppers from "./Stepper";
import { useStripe } from "@stripe/react-stripe-js";
import MessageAlert from "../../../../Components/Alert";
import Loading from "../../../../Components/Loading/Loading";
import MethodsForm from './Forms/MethodsForm';
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    boxWrapper: {
        display: 'flex',
        justifyContent: 'center',  // Centers horizontally
        width: '100%',             // Full width
    },
    paper: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: theme.shadows[3],
        minWidth: '90%',              // Make it responsive
    },
}));

const Main = ({isSelectable, user, planInfo, alert, handleAlert, loading, handleClose}) => {

    const stripe = useStripe();
    const classes = useStyles();
    const [showStepper, setShowStepper] = useState(false);

    const openStepper = () => {
        setShowStepper(true);
    };

    const closeStepper = () => {
        setShowStepper(false);
    };

    return (
        <Box component="main" className={classes.boxWrapper}>
            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleClose}
                severity={alert.severity}
            />

            <Paper className={classes.paper} elevation={5}>

                {isSelectable && <Typography variant={"h5"}>Payment Form</Typography> }

                <Divider style={{ margin: "10px 0px" }} />


                { loading && <Loading size={20}/> }
                {showStepper ? (
                    <CustomizedSteppers
                        stripe={stripe}
                        handleAlert={handleAlert}
                        customerId={user?.CusId}
                        closeStepper={closeStepper}
                    />
                ) : (
                    <MethodsForm
                        stripe={stripe}
                        handleAlert={handleAlert}
                        user={user}
                        onAdd={openStepper}
                        priceId={planInfo.priceId}
                        isSelectable={isSelectable}/>
                )}
            </Paper>

        </Box>
    );
};

export default Main;
