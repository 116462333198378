import React, { useState } from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
    Grid,
    Button,
    TextField,
    Typography,
    Link,
} from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MessageAlert from "../../Components/Alert/Alert";
import { withErrorHandlingAndLoading } from "../../constants/functions";
import Loading from "../../Components/Loading/Loading";
import { addUser } from "../../constants/users";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    formContainer: {
        marginTop: 40,
        width: "100%",
        maxWidth: 600,
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    },
    signUpButton: {
        margin: theme.spacing(2, 0),
    },
    policy: {
        marginTop: theme.spacing(1),
        display: "flex",
        alignItems: "center",
    },
    loginButton: {
        marginTop: theme.spacing(2),
    },
}));

const SignUp = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: "", severity: "" });
    const [signUpSuccess, setSignUpSuccess] = useState(false); // New state for success message

    const handleAlertOpen = (open, message, severity) => {
        setAlert({ open, message, severity });
    };
    const handleAlertClose = () => {
        setAlert((prevState) => ({ ...prevState, open: false }));
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        username: Yup.string().required("Username is required"),
        email: Yup.string().email("Invalid email").required("Email is required"),
        organization: Yup.string().required("Organization is required"),
        password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
    });

    const handleSignUp = withErrorHandlingAndLoading(async (values, { setSubmitting, resetForm }) => {
        await addUser(false, values);
        setSubmitting(false);
        resetForm();
        setSignUpSuccess(true); // Set success state after successful sign-up
    }, setLoading, handleAlertOpen);

    return (
        <div className={classes.root}>
            <MessageAlert open={alert.open} message={alert.message} onClose={handleAlertClose} severity={alert.severity} />

            {signUpSuccess ? (
                <div className={classes.formContainer}>
                    <Typography variant="h5" align="center">
                        Check your email!
                    </Typography>
                    <Typography color="textSecondary" align="center">
                        An email has been sent to you. Please check your inbox to verify your account.
                    </Typography>
                    <Button
                        component={RouterLink}
                        to="/signin"
                        variant="contained"
                        color="primary"
                        className={classes.loginButton}
                        fullWidth
                    >
                        Go to Login
                    </Button>
                </div>
            ) : (
                <Formik
                    initialValues={{ name: "", username: "", email: "", organization: "", password: "" }}
                    validationSchema={validationSchema}
                    onSubmit={handleSignUp}
                >
                    {({ isSubmitting, errors, touched }) => (
                        <Form className={classes.formContainer}>
                            <Typography variant="h5" align="center">
                                Create new account
                            </Typography>
                            <Typography color="textSecondary" gutterBottom align="center">
                                Use your email to create new account
                            </Typography>

                            <Grid style={{ marginTop: 10 }} container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        as={TextField}
                                        size={"small"}
                                        className={classes.textField}
                                        fullWidth
                                        label="First/Last Name"
                                        name="name"
                                        variant="outlined"
                                        error={touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        as={TextField}
                                        size={"small"}
                                        className={classes.textField}
                                        fullWidth
                                        label="Username"
                                        name="username"
                                        variant="outlined"
                                        error={touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        size={"small"}
                                        className={classes.textField}
                                        fullWidth
                                        label="Email address"
                                        name="email"
                                        variant="outlined"
                                        error={touched.email && !!errors.email}
                                        helperText={touched.email && errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        size={"small"}
                                        className={classes.textField}
                                        fullWidth
                                        label="Organization"
                                        name="organization"
                                        variant="outlined"
                                        error={touched.organization && !!errors.organization}
                                        helperText={touched.organization && errors.organization}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        as={TextField}
                                        size={"small"}
                                        className={classes.textField}
                                        fullWidth
                                        label="Password"
                                        name="password"
                                        type="password"
                                        variant="outlined"
                                        error={touched.password && !!errors.password}
                                        helperText={touched.password && errors.password}
                                    />
                                </Grid>
                            </Grid>

                            {isSubmitting ? (
                                <Loading />
                            ) : (
                                <Button
                                    className={classes.signUpButton}
                                    color="primary"
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    Sign up now
                                </Button>
                            )}

                            <Typography color="textSecondary" variant="body1" align="center">
                                Have an account?{" "}
                                <Link component={RouterLink} to="/signin">
                                    Sign in
                                </Link>
                            </Typography>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

SignUp.propTypes = {
    history: PropTypes.object,
};

export default withRouter(SignUp);
