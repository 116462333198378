import React, {useCallback, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    makeStyles,
    Paper,
    Step,
    StepLabel,
    Stepper,
} from '@material-ui/core';
import MessageAlert from "../../Components/Alert";
import {StepContent} from "./StepِContent";

const useStyles = makeStyles((theme) => ({
    grid: {
        background : theme.palette.background.default
    },
    stepContent: {
        margin: 10,
        flex: '0 0 80%', // Center the active step
        transition: 'transform 0.5s ease',
        opacity: 1,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
}));

const steps = ['Thing', 'Topic', 'Connectors', 'Flow', 'Model'];

const Steps = (props) => {
    const { history } = props;
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [values, setValues] = useState({
        thing: {
            thing_id: null, // or a default ID if applicable
            name: '', // default name
            permission: '', // Initialize permissions specific to the thing
        },
        topic: {
            topic_id: null, // or a default ID if applicable
            name: '', // default name
        },
        connectors: {
            sink: null, // default value for sink connector
            source: null, // default value for source connector
        },
        api : {
            modelName :'',
        },
        flowList : []
    });
    const [loading, setLoading] = useState(false);
    const [stateDialog, setStateDialog] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: '', severity: 'info' });

    const handleAlert = useCallback((open, message, severity) => { setAlert({ open, message, severity }); }, []);
    const closeAlert = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

    // Check whether the current step is valid
    const isStepValid = (step) => {
        // Example validation: Check if all required fields are filled for each step
        switch (step) {
            case 0: // Thing step
                return true //values.thing.name && values.thing.permission ;
            case 1: // Topic step
                return true//Boolean(values.topic.name);
            case 2: // Connectors step
                return true//values.connectors.sink && values.connectors.source;
            case 3: // Flow step
                // Add your flow validation logic
                return true;
            case 4: // API step
                // Add your API validation logic
                return true;
            default:
                return true;
        }
    };

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            setActiveStep(0);
            setValues({
                thing: {
                    thing_id: null,
                    name: '',
                    permission: '',
                },
                topic: {
                    topic_id: null,
                    name: '',
                },
                connectors: {
                    sink: null,
                    source: null,
                },
            });
            handleAlert(true, `Process finished successfully`, 'success');
        }
        else {
            setActiveStep((prev) => Math.min(prev + 1, steps.length - 1));
        }
    };

    const handleBack = () => {
        setActiveStep((prev) => Math.max(prev - 1, 0));
    };

    return (
        <Grid row container justifyContent="center" spacing={2}>
            <Grid item xs={12} md={10} className={classes.grid}>
                <Paper>
                    <MessageAlert
                        open={alert.open}
                        message={alert.message}
                        severity={alert.severity}
                        onClose={closeAlert}
                    />
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Paper>
            </Grid>
            <Grid item xs={12} md={10} className={classes.grid}>
                <Paper style={{ padding: 10 }}>
                    <StepContent
                        stepIndex={activeStep}
                        {...{ values, setValues, history, setLoading, handleAlert, loading }}
                        isStepValid={isStepValid(activeStep)}
                    />
                    <div className={classes.buttonContainer}>
                        <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!isStepValid(activeStep)}
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </div>
                </Paper>

                <Dialog fullScreen open={stateDialog} onClose={() => setStateDialog(false)}>
                    <DialogTitle>Reset</DialogTitle>
                    <DialogContent>Do you want to resume the pipeline process?</DialogContent>
                    <DialogActions>
                        <Button onClick={() => setStateDialog(false)} color="primary">No</Button>
                        <Button onClick={() => setActiveStep(0)} color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    );
};

export default Steps;
