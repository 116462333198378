import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    CardContent,
    Grid,
    Divider,
    FormControl,
    InputLabel,
    Select,
    Typography,
    Button,
    TextField,
    DialogActions,
} from '@material-ui/core';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStateValue } from "../../../../StateContext";
import { addApi, fetchKafkaTopics, updateApi, withErrorHandlingAndLoading } from "../../../../constants/functions";
import { actionTypes } from "../../../../StateContext/types";
import Loading from "../../../../Components/Loading/Loading";
import {ConnectorForm} from "../../../ConnectorsList/components";

const validationSchema = Yup.object({
    modelName: Yup.string().required('API Name is required'),
    modelID: Yup.string().required('Model ID is required'),
    host: Yup.string().required('Host is required'),
    topic: Yup.string().required('Topic is required'),
});

const ApiForm = ({ initialValues, isEditMode, handleAlert,onClose,setValues, topic  }) => {
    const { kafkaTopicsState, kafkaTopicsDispatch, apisDispatch } = useStateValue();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = withErrorHandlingAndLoading(async () => {
            const topics = await fetchKafkaTopics();
            kafkaTopicsDispatch({ type: actionTypes.SET_TOPICS, value: topics.topics });
        }, setLoading, handleAlert);

        fetchData();
    }, []);

    const formik = useFormik({
        initialValues: initialValues || {
            modelName: '',
            modelID: '',
            username: '',
            password: '',
            host: '',
            topic : topic || '',
            Apikey: ''
        },
        validationSchema: validationSchema,
        onSubmit: withErrorHandlingAndLoading(async (values) => {
            if (isEditMode) {
                await updateApi(values);
                handleAlert(true, 'API updated successfully', 'success');
                apisDispatch({
                    type: actionTypes.UPDATE_API,
                    value: values
                });
            } else {
                const newApi = await addApi(values);
                formik.resetForm();
                handleAlert(true, 'API added successfully', 'success');
                apisDispatch({
                    type: actionTypes.ADD_API,
                    value: newApi
                });
                setValues &&
                setValues((prevValues) => ({
                    ...prevValues,
                    api: newApi,
                }))
            }
            await onClose();
        }, setLoading, handleAlert)
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <CardContent>
                <Grid container spacing={3}>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="API Name"
                            name="modelName"
                            variant="outlined"
                            margin="dense"
                            value={formik.values.modelName}
                            onChange={formik.handleChange}
                            error={formik.touched.modelName && Boolean(formik.errors.modelName)}
                            helperText={formik.touched.modelName && formik.errors.modelName}
                        />
                        <TextField
                            fullWidth
                            label="Host"
                            name="host"
                            variant="outlined"
                            margin="dense"
                            value={formik.values.host}
                            onChange={formik.handleChange}
                            error={formik.touched.host && Boolean(formik.errors.host)}
                            helperText={formik.touched.host && formik.errors.host}
                        />
                        <TextField
                            fullWidth
                            label="Username"
                            name="username"
                            variant="outlined"
                            margin="dense"
                            value={formik.values.username}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <FormControl fullWidth variant="outlined" margin="dense">
                            <InputLabel htmlFor="topic">Topic</InputLabel>
                            <Select
                                disabled={topic}
                                native
                                label="Topic"
                                name="topic"
                                value={formik.values.topic}
                                onChange={formik.handleChange}
                                error={formik.touched.topic && Boolean(formik.errors.topic)}
                            >
                                <option aria-label="None" value="" />
                                {kafkaTopicsState.map(topic => (
                                    <option key={topic.name} value={topic.id}>
                                        {topic.name}
                                    </option>
                                ))}
                            </Select>
                            {formik.touched.topic && formik.errors.topic && (
                                <Typography color="error">{formik.errors.topic}</Typography>
                            )}
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Model ID"
                            name="modelID"
                            variant="outlined"
                            margin="dense"
                            value={formik.values.modelID}
                            onChange={formik.handleChange}
                            error={formik.touched.modelID && Boolean(formik.errors.modelID)}
                            helperText={formik.touched.modelID && formik.errors.modelID}
                        />
                        <TextField
                            fullWidth
                            label="Password"
                            name="password"
                            type="password"
                            variant="outlined"
                            margin="dense"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label="API Key"
                            name="Apikey"
                            variant="outlined"
                            margin="dense"
                            value={formik.values.Apikey}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
                <Button type="submit" color="primary" variant="contained">
                    {isEditMode ? 'Update' : 'Save'}
                </Button>
                {loading && <Loading />}
            </DialogActions>
        </form>
    );
};

ApiForm.propTypes = {
    initialValues: PropTypes.object,
    isEditMode: PropTypes.bool,
    handleAlert: PropTypes.func.isRequired
};

export default ApiForm;
