import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Box, CssBaseline } from '@material-ui/core';
import DrawerComponent from './Drawer';
import AppBar from './components/AppBar';
import Footer from "./components/Footer";

import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import LoopIcon from '@material-ui/icons/Loop';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from '@material-ui/icons/People';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SettingsIcon from '@material-ui/icons/Settings';
import ReceiptIcon from '@material-ui/icons/Receipt';
import GetAppIcon from '@material-ui/icons/GetApp';
import GrainIcon from '@material-ui/icons/Grain';
import MemoryIcon from '@material-ui/icons/Memory';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';

import { getUser } from "../../Utils/common";
import clsx from "clsx";
import {fetchNotifications, withErrorHandlingAndLoading} from "../../constants/functions";
import {useStateValue} from "../../StateContext";
import {actionTypes} from "../../StateContext/types";
import MessageAlert from "../../Components/Alert";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
}));

const Main = (props) => {

    const { children, url, history } = props;
    const classes = useStyles();
    const {drawerState, drawerDispatch} = useStateValue();
    const { notificationsDispatch} = useStateValue();
    const [loading,setLoading] = useState(false);

    const [alert, setAlert] = useState({
        open : false , message : "" , severity : "info"
    });
    const handleClose = () => {
        setAlert(prevState => ({ ...prevState, open: false }));
    };

    const handleAlert = (open, message, severity) =>{
        setAlert({
            open : open ,
            message :message ,
            severity: severity
        })
    }

    const handleDrawerOpen = async () => {
        await drawerDispatch({
            type : actionTypes.OPEN_DRAWER,
        });
    };

    const handleDrawerClose = async () => {
        await drawerDispatch({
            type : actionTypes.CLOSE_DRAWER,
        });
    };

    const fetchData = withErrorHandlingAndLoading(async () => {
       const response = await fetchNotifications();
        await notificationsDispatch({
            type : actionTypes.SET_NOTIFICATIONS,
            value : response
        });
    }, setLoading , handleAlert );

    useEffect(() => {fetchData() }, []);

    const usr = getUser();

    const pages = [
        { title: 'Dashboard', href: '/dashboard', icon: <DashboardIcon /> },
        { title: 'Process', href: '/steps', icon: <LoopIcon /> },
        { title: 'Things', href: '/things', icon: <SettingsInputAntennaIcon /> },
        { title: 'Plans', href: '/plan', icon: <ReceiptIcon /> },
        { title: 'Topics', href: '/topics', icon: <GrainIcon /> },
        { title: 'Connectors', href: '/connectors', icon: <SettingsInputHdmiIcon /> },
        { title: 'AI Models', href: '/ai-models', icon: <MemoryIcon /> },
        { title: 'Flows', href: '/flows', icon: <AccountTreeIcon /> },
        { title: 'DataStores', href: '/datastores', icon: <FindInPageIcon /> },
        { title: 'Downloads', href: '/downloads', icon: <GetAppIcon /> },
        { title: 'Account', href: '/account', icon: <AccountBoxIcon /> },
        { title: 'Settings', href: '/settings', icon: <SettingsIcon /> },
    ];

    if (usr != null && usr.admin) {
        pages.push({ title: 'Users', href: '/users', icon: <PeopleIcon /> });
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity}/>
            <AppBar
                open={drawerState}
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                url={url}
                fetchData={fetchData}
                loading={loading}
                setLoading={setLoading}
                handleAlert={handleAlert}
            />
            <DrawerComponent
                open={drawerState}
                handleDrawerClose={handleDrawerClose}
                pages={pages}
                history={history}
                url={url}
            />
            <main className={clsx(classes.content, { [classes.contentShift]: drawerState })}>
                <div className={classes.drawerHeader} />
                {children}
                <Box pt={4}>
                    <Footer />
                </Box>
            </main>
        </div>
    );
};

export default Main;
