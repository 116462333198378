import React, { createContext, useContext, useReducer } from 'react';
import {
    initialFormValuesState,
    formValuesReducer,
    metadataReducer,
    productsReducer,
    usersReducer,
    connectorsReducer,
    connectorInfoReducer,
    kafkaTopicsReducer,
    thingsReducer,
    databasesReducer,
    notificationsReducer,
    flowsReducer,
    drawerReducer,
    apisReducer,
    sourcesReducer,
    billingAddressesReducer,
    paymentMethodsReducer,

} from './reducer';
// Combine initial states
const initialState = {
    formValues: initialFormValuesState,
    metadata: {
        productId: null,
        productName: null,
        price: null,
        devices: 0,
        messages: 0,
        level: 0
    },
};

const StateContext = createContext();

export const StateProvider = ({ children }) => {

    const [formValuesState, formValuesDispatch] = useReducer(formValuesReducer, initialState.formValues);
    const [metadataState, metadataDispatch] = useReducer(metadataReducer, initialState.metadata);
    const [productsState, productsDispatch] = useReducer(productsReducer, []);
    const [usersState, usersDispatch] = useReducer(usersReducer, []);
    const [connectorsState, connectorsDispatch] = useReducer(connectorsReducer, []);
    const [connectorInfoState, connectorInfoDispatch] = useReducer(connectorInfoReducer, []);
    const [kafkaTopicsState, kafkaTopicsDispatch] = useReducer(kafkaTopicsReducer, []);
    const [thingsState, thingsDispatch] = useReducer(thingsReducer, []);
    const [databasesState, databasesDispatch] = useReducer(databasesReducer, []);
    const [sourcesState, sourcesDispatch] = useReducer(sourcesReducer, []);
    const [notificationsState, notificationsDispatch] = useReducer(notificationsReducer, []);
    const [flowsState, flowsDispatch] = useReducer(flowsReducer, []);
    const [drawerState, drawerDispatch] = useReducer(drawerReducer, true);
    const [apisState, apisDispatch] = useReducer(apisReducer, []);
    const [billingAddressesState, billingAddressesDispatch] = useReducer(billingAddressesReducer, []);
    const [paymentMethodsState, paymentMethodsDispatch] = useReducer(paymentMethodsReducer, []);


    return (
        <StateContext.Provider
            value={{
                formValuesState, formValuesDispatch,
                metadataState, metadataDispatch,
                productsState, productsDispatch,
                usersState, usersDispatch ,
                connectorsState , connectorsDispatch,
                connectorInfoState, connectorInfoDispatch,
                kafkaTopicsState, kafkaTopicsDispatch,
                thingsState, thingsDispatch,
                databasesState, databasesDispatch ,
                notificationsState, notificationsDispatch,
                flowsState, flowsDispatch,
                drawerState, drawerDispatch,
                apisState, apisDispatch ,
                sourcesState, sourcesDispatch,
                billingAddressesState, billingAddressesDispatch,
                paymentMethodsState, paymentMethodsDispatch
            }}
        >
            {children}
        </StateContext.Provider>
    );
};

export const useStateValue = () => useContext(StateContext);
