import React, {useState} from "react";
import {AddAclRecord, withErrorHandlingAndLoading} from "../../../constants/functions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Button, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import DialogActions from "@material-ui/core/DialogActions";

export const PermissionForm = ({openDialog, handleCloseDialog, selectData, setLoading, handleAlert, fetchDataAcl}) => {

    const [valuesAcl, setValuesAcl] = useState({name: '', pubsub: 3});

    const handleChangeAcl = (event) => {
        setValuesAcl({
            ...valuesAcl,
            [event.target.name]: event.target.value,
        });
    };

    const handleAddAclRecord = withErrorHandlingAndLoading(async () => {
        const newAcl = {
            aclUserId: selectData.id,
            deviceId: selectData.device_id,
            topicPath: valuesAcl.name,
            pubsub: valuesAcl.pubsub,
        }
        const response = await AddAclRecord(newAcl);
        handleCloseDialog({ topic : `${selectData.device_id}/${valuesAcl.name}`, acl_id : response.id});
        fetchDataAcl()
    }, setLoading, handleAlert);

    return (
        <Dialog open={openDialog.add} onClose={handleCloseDialog}>
            <DialogTitle>Add Permission</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {/* Add your content here */}
                </DialogContentText>
                <TextField
                    fullWidth
                    label="Topic path"
                    margin="dense"
                    name="name"
                    variant="outlined"
                    value={valuesAcl.name}
                    onChange={handleChangeAcl}
                />
                <FormControl fullWidth>
                    <InputLabel>Apply to</InputLabel>
                    <Select
                        native
                        value={valuesAcl.pubsub}
                        onChange={handleChangeAcl}
                        inputProps={{
                            name: 'pubsub',
                            id: 'pubsub-select',
                        }}
                    >
                        <option value={3}>Publishes & Subscriptions</option>
                        <option value={2}>Publishes</option>
                        <option value={1}>Subscriptions</option>
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAddAclRecord} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}