import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom"; // Import useParams
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import MessageAlert from "../../Components/Alert";
import Loading from "../../Components/Loading/Loading";
import { activateAccount } from "../../constants/users";
import { Dialog } from "@material-ui/core";
import {setDefaultMethod} from "../../constants/stripe";
import {handleRedirectToLogin, withErrorHandlingAndLoading} from "../../constants/functions";
import {RequestTokenForm} from "./RequestTokenForm";
import DialogContent from "@material-ui/core/DialogContent";  // Import Dialog components

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
    },
    paper: {
        marginTop: 40,
        width: '100%',
        maxWidth: 600,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    }
}));

const UserActivation = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info',
    });
    const [isActivated, setIsActivated] = useState(false);
    const [tokenExpired, setTokenExpired] = useState(false);
    const { token } = useParams();
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleAlert = useCallback((open, message, severity) => {
        setAlert({ open, message, severity });
    }, []);

    const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

    const handleActivateAccount = withErrorHandlingAndLoading (async (token) => {
        try {
            await activateAccount(token);
            setIsActivated(true);
            handleAlert(true, 'Your account has been successfully activated!', 'success');
        } catch (error) {
            console.log(error);
            if (error.message === 'Token expired, please sign in again') {
                setTokenExpired(true);
                handleAlert(true, 'Your activation link has expired. Please request a new one.', 'error');
                setDialogOpen(true);  // Open the dialog for requesting a new token
            } else {
                handleAlert(true, 'An error occurred while activating your account.', 'error');
            }
        }
    },setLoading,handleAlert);

    return (
        <>
            <Container className={classes.root} component="main" maxWidth="xs">
                <CssBaseline />
                <MessageAlert
                    open={alert.open}
                    message={alert.message}
                    onClose={handleClose}
                    severity={alert.severity}
                />
                <div className={classes.paper}>
                    { !tokenExpired && <>
                        <Typography variant={"h6"}>
                        { !isActivated ? 'User Activation' : 'Success'}
                        </Typography>
                        {!isActivated  &&
                             <Typography variant={"caption"}>
                                Click the button below to activate your account.
                             </Typography>
                        }
                        {loading && <Loading />}
                        {!loading && !isActivated && (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => handleActivateAccount(token)}
                            >
                                Activate Account
                            </Button>
                        )}
                            </>
                    }

                    {isActivated && (
                        <>
                            <Typography variant="caption" align="center">
                                Your account has been activated! You can now log in.
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                style={{
                                    marginTop: "20px",
                                    backgroundColor: "#11b276",
                                    color: "#fff",
                                }}
                                className={classes.submit}
                                onClick={handleRedirectToLogin}
                                disabled={loading}
                            >
                                Go to login page
                            </Button>
                        </>
                    )}

                    {tokenExpired && (
                        <>
                            <Typography variant={"h6"}>
                                Token Activation Expired
                            </Typography>
                            <Typography variant="caption">
                                Your activation link has expired. Click below to request a new link.
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => setDefaultMethod(true)}
                            >
                                Request New Link
                            </Button>
                        </>
                    )}
                </div>
            </Container>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogContent>
                    <RequestTokenForm isTokenExpired userActivation />
                </DialogContent>
            </Dialog>

        </>
    );
};

export default UserActivation;
