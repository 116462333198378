import React, {useCallback, useState} from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MessageAlert from "../../Components/Alert";
import {RequestTokenForm} from "./RequestTokenForm";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(2),
    },
    paper: {
        marginTop: 40,
        width: '100%',
        maxWidth: 600,
        padding: theme.spacing(4),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[5],
    }
}));

const RequestToken = () => {
    const classes = useStyles();
    return (
        <Container className={classes.root} component="main" maxWidth="xs">
            <CssBaseline/>

            <div className={classes.paper}>
                <RequestTokenForm passwordReset />
            </div>
        </Container>
    );
};

export default RequestToken;
