import React from 'react';
import { TextField, Grid, Typography } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import { useStateValue } from "../../../../../StateContext";
import StripeInput from '../../components/StripeInput'
import {actionTypes} from "../../../../../StateContext/types";

const PaymentForm = ({type}) => {
    const { formValuesState, formValuesDispatch } = useStateValue();

    const cardsLogo = [
        "dankort",
        "discover",
        "jcb",
        "maestro",
        "mastercard",
        "visa",
        "visaelectron",
    ];

    const handleCurrencyChange = (event, value) => {
        formValuesDispatch({
            type: actionTypes.EDIT_FORM_VALUE,
            key: "currency",
            value: value
        });
    };

    return (
        <>
            <Grid container item xs={12}>
                <Grid item xs={12} sm={3}>
                    <Typography variant="h6">Payment Data</Typography>
                </Grid>
                <Grid container item xs={12} sm={6} justifyContent="space-between">
                    {cardsLogo.map(e => <img key={e} src={`/cards/${e}.png`} alt={e} width="50px" align="bottom" style={{ padding: "0 5px" }} />)}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Autocomplete
                    options={currencies}
                    getOptionLabel={option => option.code}
                    renderOption={option => <>{option.name} ({option.code})</>}
                    renderInput={params =>
                        <TextField
                            label="Currency"
                            name="currency"
                            variant="outlined"
                            fullWidth
                            {...params}
                        />
                    }
                    value={formValuesState.currency}
                    onChange={handleCurrencyChange}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Credit Card Number"
                    name="ccnumber"
                    variant="outlined"
                    required
                    fullWidth
                    InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardNumberElement
                        },
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="Expiration Date"
                    name="ccexp"
                    variant="outlined"
                    required
                    fullWidth
                    InputProps={{
                        inputProps: {
                            component: CardExpiryElement
                        },
                        inputComponent: StripeInput
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    label="CVC"
                    name="cvc"
                    variant="outlined"
                    required
                    fullWidth
                    InputProps={{
                        inputProps: {
                            component: CardCvcElement
                        },
                        inputComponent: StripeInput
                    }}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
        </>
    );
}

export default PaymentForm;

const currencies = [
    {
        "symbol": "€",
        "name": "Euro",
        "symbol_native": "€",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "EUR",
        "name_plural": "euros"
    },
    {
        "symbol": "$",
        "name": "US Dollar",
        "symbol_native": "$",
        "decimal_digits": 2,
        "rounding": 0,
        "code": "USD",
        "name_plural": "US dollars"
    },
]
