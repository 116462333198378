import React, { useState, useCallback } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import MessageAlert from "../../Components/Alert";
import { updatePassword } from "../../constants/auth"; // Create this function in your auth service
import PasswordForm from "./PasswordForm";
import {withErrorHandlingAndLoading} from "../../constants/functions";

const PasswordUpdate = () => {
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ open: false, message: "", severity: "info" });
    const [updateDone, setUpdateDone] = useState(false);

    const handleAlert = useCallback((open, message, severity) => {
        setAlert({ open, message, severity });
    }, []);

    const handleClose = useCallback(() => setAlert((prevState) => ({ ...prevState, open: false })), []);

    const handleUpdatePassword = withErrorHandlingAndLoading(async (newPassword, confirmPassword) => {
        if (newPassword !== confirmPassword) {
            handleAlert(true, "Passwords do not match", "error");
            return;
        }
        try {
            await updatePassword(newPassword); // This should be the API to update password
            handleAlert(true, "Password successfully updated.", "success");
            setUpdateDone(true);
        } catch (error) {
            console.error(error);
            handleAlert(true, "An error occurred while updating your password.", "error");
        }
    }, setLoading, handleAlert);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <MessageAlert open={alert.open} message={alert.message} onClose={handleClose} severity={alert.severity} />
            <div>
                <PasswordForm title={updateDone ? "Success" : "Update Password"} onSubmit={handleUpdatePassword} loading={loading} />
            </div>
        </Container>
    );
};

export default PasswordUpdate;
