import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Avatar, Badge, IconButton, Menu, Toolbar, Typography, useMediaQuery,useTheme,} from '@material-ui/core';
import { Menu as MenuIcon, NotificationsOutlined as NotificationsIcon,} from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {Link as RouterLink} from 'react-router-dom';
import {clsx} from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import Loading from '../../../../Components/Loading/Loading';
import {useStateValue} from '../../../../StateContext';
import {getUser, removeUserSession} from "../../../../Utils/common";
import {NotificationMenu} from "./Notifications";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Tooltip from "@material-ui/core/Tooltip";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0, // Initial state when drawer is closed
        minWidth: drawerWidth, // Ensure minimum width even when drawer is closed
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth, // State when drawer is open
    },
    flexGrow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    avatar: {
        cursor: 'pointer',
        width: 32,
        height: 32,
    },
    avatarSmall: {
        width: theme.spacing(3), // Adjust the size as needed
        height: theme.spacing(3),
    },
}));


const AppBarComponent = ({open, handleDrawerOpen, handleDrawerClose, url, handleAlert}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [loading,setLoading] =  useState(false);
    const openMenu = Boolean(anchorEl);
    const { notificationsState } = useStateValue();
    const usr = getUser();
    const user = { name: usr.name, avatar:`${url}/${usr.location}`, bio: usr.email };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        removeUserSession();
        window.location.href = '/signin';
    };

    function openNotifications(e) {
        setAnchorEl(e.currentTarget)
    }

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: open})}>
            <Toolbar>
                {open ? (
                    <IconButton color="inherit" onClick={handleDrawerClose} edge="start" className={classes.menuButton}>
                        <MenuOpenIcon />
                    </IconButton>
                ) : (
                    <IconButton color="inherit" onClick={handleDrawerOpen} edge="start" className={classes.menuButton}>
                        <MenuIcon />
                    </IconButton>
                )}

                <Avatar className={classes.avatarSmall} onClick={handleDrawerOpen} alt="Thingwings" src="logo.png"/>
                 {!isSmallScreen && <Typography variant="h6" noWrap> ThingWings </Typography>}

                <div className={classes.flexGrow}/>

                <Tooltip title={'Notifications'}>
                    <IconButton color="inherit" onClick={openNotifications}>
                        <Badge overlap="rectangular" badgeContent={loading ? (<Loading size={12}/>)
                            : (notificationsState.filter((item) => !item.seen).length)
                            }
                            color="error"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Tooltip>

                <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose}>
                    <NotificationMenu setLoading={setLoading} handleAlert={handleAlert}/>
                </Menu>

                <Tooltip title={'Grafana'}>
                    <IconButton  onClick={() => window.open(`${process.env.REACT_APP_GRAFANA_URL}`)}>
                        <Avatar  className={classes.avatarSmall}  alt="Grafana" src="grafana.svg"/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={'Profile'}>
                    <IconButton  color="inherit" component={RouterLink} to="/account">
                       { usr.location ?
                           <Avatar alt="Person" className={classes.avatar} src={user.avatar}/> :
                            <AccountCircleIcon />
                       }
                    </IconButton>
                </Tooltip>

                <Tooltip title={'Logout'}>
                    <IconButton color="inherit" onClick={handleLogout}>
                        <ExitToAppIcon />
                    </IconButton>
                </Tooltip>

            </Toolbar>
        </AppBar>
    );
};

AppBarComponent.propTypes = {
    open: PropTypes.bool.isRequired,
    handleDrawerOpen: PropTypes.func.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    handleAlert: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default AppBarComponent;
