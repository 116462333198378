import Iframe from "react-iframe";
import * as PropTypes from "prop-types";
import React from "react";

export function IFrame(props) {
    return <Iframe
        styles={{padding: 20}}
        url={`${process.env.REACT_APP_DNS_URL}/flow-editor/${props.containerName}/?token=${props.token}`}
        width="100%"
        height="500px" // Adjust the height as needed
        position="relative"
        onLoad={props.onLoad}
        allowFullScreen
    />;
}

IFrame.propTypes = {
    containerName: PropTypes.string,
    token: PropTypes.any,
    onLoad: PropTypes.func
};