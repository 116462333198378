import React, {useCallback, useEffect, useState} from 'react';
import Header from "./Views/Header";
import Main from "./Views/Main"
import {Elements} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';
import {getUserFromLocalStorage, getQueryParam, withErrorHandlingAndLoading} from '../../../constants/functions';
import { publishableKeyGet} from "../../../constants/stripe";
import {Typography} from "@material-ui/core";

const App = () => {

    const [user, setUser] = useState(null);
    const [planInfo, setPlanInfo] = useState({ planName: '', priceId: '' });
    const [loading, setLoading] = useState(false);
    const [stripePromise, setStripePromise] = useState(null);
    const [isSelectable, setIsSelectable] = useState(false)

    const [alert, setAlert] = useState({open: false, message: '', severity: 'info'});
    const handleAlert = useCallback((open, message, severity) => {
        setAlert({open, message, severity});
    }, []);
    const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);

    useEffect(() => {
        // Check if the current path is "/plan/payments"
        if (window.location.pathname === '/plan/payments') {
            setIsSelectable(true);
        } else {
            setIsSelectable(false);
        }
    }, []);


    useEffect(() => {

        const fetchUserData = async () => {
            const user = getUserFromLocalStorage();
            if (user && user.CusId) {
                setUser(user);
                setPlanInfo({
                    planName: getQueryParam('planName'),
                    priceId: getQueryParam('priceId')
                });
            }
        };

        const retrievePublishableKey = withErrorHandlingAndLoading(async () => {
            const publishableKey = await publishableKeyGet();
            const stripe = loadStripe(publishableKey);
            setStripePromise(stripe);
        },setLoading , handleAlert);

        retrievePublishableKey();
        fetchUserData();

    }, [user?.CusId, planInfo.planName, planInfo.priceId]);

    return (
      <div style={{ flexGrow: 1 }}>
          {stripePromise && (
              <Elements stripe={stripePromise}>
                <Main
                    alert={alert}
                    user={user}
                    planInfo={planInfo}
                    isSelectable={isSelectable}
                    handleAlert={handleAlert}
                    loading={loading}
                    setLoading={setLoading}
                    handleClose={handleClose}
                />
              </Elements>
          )}
      </div>
    )
}
export default App;
