import axios from "axios";
import {base_url} from "./functions";
import {errorHandler} from "./errorHandler";

const auth_url = base_url + '/auth';

export const login = async (credentials) => {
    try {
        const response = await axios.post(`${auth_url}/login`, credentials);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const sendResetEmail = async (email,type) => {
    try {
        const response = await axios.post(`${auth_url}/send-email`, { email, type });
        return response.data;
    } catch (error) {
        throw new Error(error.response?.data?.message || "Failed to send reset email.");
    }
};

export const resetPasswordWithToken = async (token, newPassword) => {
    try {
        const response = await axios.post(
            `${auth_url}/password-reset`,
            { newPassword },
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.log(error)
        errorHandler(error);
    }
};

export const updatePassword = async (newPassword) => {
    try {
        const response = await axios.post(
            `${auth_url}/password-reset`,
            { newPassword }
        );
        return response.data;
    } catch (error) {
        console.log(error)
        errorHandler(error);
    }
};



