import React, { useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button ,DialogContentText
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from "axios";
import {getToken} from "../../Utils/common";
import {deleteAclRecord, withErrorHandlingAndLoading} from "../../constants/functions";
import Loading from "../../Components/Loading/Loading";

const CustomList = ({ aclUserId, handleAlert, fetchDataAcl, Acls }) => {

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedAclId, setSelectedAclId] = useState(null);
    const [loading,setLoading] = useState(false)

    const handleClickDelete = (aclId) => {
        setSelectedAclId(aclId);
        setOpenDeleteDialog(true);
    };

    const handleConfirmDelete = withErrorHandlingAndLoading(async () => {
        await deleteAclRecord(aclUserId,selectedAclId);
        //setAcls(prevRecords => prevRecords.filter(record => record.id !== selectedAclId));
        fetchDataAcl()
        setOpenDeleteDialog(false);
    }, setLoading, handleAlert);


    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Topic</TableCell>
                        <TableCell>Access Type</TableCell>
                        <TableCell>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Acls.map((acl) => (
                        <TableRow key={acl.acl_id}>
                            <TableCell>{acl.topic}</TableCell>
                            <TableCell>{acl.rw == 3 ? 'Publishes & Subscriptions' : (acl.rw == 1 ? 'Subscriptions' : 'Publishes')}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleClickDelete(acl.acl_id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            {/* Delete Confirmation Dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this permission?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={loading} onClick={handleConfirmDelete} color="primary">
                        Delete
                    </Button>
                    {
                        loading && <Loading size={24}/>
                    }
                </DialogActions>
            </Dialog>
        </TableContainer>
    );
};

export default CustomList;
