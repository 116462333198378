import React, { useCallback, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import { actionTypes } from "../../StateContext/types";
import { convertIntoDate, deleteFlow, fetchFlows, withErrorHandlingAndLoading } from "../../constants/functions";
import MessageAlert from "../../Components/Alert";
import SearchInput from "../../Components/SearchInput";
import Loading from "../../Components/Loading/Loading";
import { useStateValue } from "../../StateContext";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { FlowForm } from "./FlowForm";

const useStyles = makeStyles(theme => ({
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
}));

const Flows = props => {

    const { history } = props;
    const classes = useStyles();
    const [flow, setFlow] = useState('');
    const { flowsState, flowsDispatch } = useStateValue();
    const [flowName, setFlowName] = useState('');
    const [alert, setAlert] = useState({
        open: false,
        message: '',
        severity: 'info'
    });
    const handleAlert = useCallback((open, message, severity) => {
        setAlert({
            open,
            message,
            severity
        });
    }, []);
    const handleClose = useCallback(() => setAlert(prevState => ({ ...prevState, open: false })), []);
    const [anchorMenu, setAnchorMenu] = useState(null);
    const openMenu = useCallback((event, value) => {
        setFlow(value);
        setAnchorMenu(event.currentTarget);
    }, []);
    const closeMenu = useCallback(() => {
        setFlow(null);
        setAnchorMenu(null);
    }, []);
    const [flowControl, setFlowControl] = useState({
        add: false,
        loadLib: false,
        edit: false,
        deploy: false,
        restart: false,
        delete: false
    });
    const handleDialogOpen = useCallback((dialogName) => {
        setFlowControl(prevState => ({
            ...prevState,
            [dialogName]: true
        }));
    }, []);
    const handleDialogClose = useCallback((dialogName) => {
        setFlowControl(prevState => ({
            ...prevState,
            [dialogName]: false
        }));
    }, []);
    const [progress, setProgress] = useState(false);
    const [loading, setLoading] = useState(false);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5); // Set the number of items per page

    useEffect(() => {
        const fetchFlowsData = withErrorHandlingAndLoading(async () => {
            const response = await fetchFlows();
            flowsDispatch({
                type: actionTypes.SET_FLOWS,
                value: response
            });
        }, setLoading, handleAlert);

        fetchFlowsData();
    }, [flowsDispatch, handleAlert]);

    const handleDelete = withErrorHandlingAndLoading(async () => {
        if (flow.id) {
            await deleteFlow(flow.id);
            flowsDispatch({
                type: actionTypes.DELETE_FLOW,
                flowId: flow.id
            });
            closeMenu();
            handleAlert(true, 'Flow deleted successfully', 'success');
            handleDialogClose('delete');
        }
    }, setProgress, handleAlert);
    const handleEdit = useCallback(() => history.push(`nodered/${flow.containerName}`), [history, flow]);

    const menuOptions = [
        {
            key: 'edit',
            label: 'Edit Flow',
            onClick: handleEdit
        },
        {
            key: 'update',
            label: 'Update Name',
            onClick: () => { handleDialogOpen('edit'); setFlowName(flow.name) }
        },
        {
            key: 'delete',
            label: 'Delete Flow',
            onClick: () => handleDialogOpen('delete')
        }
    ];

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = useCallback((event) => setSearchQuery(event.target.value), []);
    const filteredData = flowsState.filter(item => item.name.toLowerCase().includes(searchQuery.toLowerCase()));

    // Handle pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <MessageAlert
                open={alert.open}
                message={alert.message}
                onClose={handleClose}
                severity={alert.severity}
            />

            <Grid container spacing={3} justifyContent="space-between">
                <Grid item xs={3}>
                    <div className={classes.row}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search flow"
                            name="search"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                </Grid>

                <Grid item xs={3}>
                    <Button variant="contained" color="primary" onClick={() => handleDialogOpen('add')}
                            style={{ float: 'right' }}>
                        Add Flow
                    </Button>
                </Grid>

                <Grid item xs={12}><Divider /></Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="List of Flows" />

                        <Grid item xs={12}><Divider /></Grid>

                        <Menu anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
                            {menuOptions.map(option => (
                                <MenuItem key={option.key} onClick={option.onClick}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Menu>

                        <Grid item xs={12}>
                            {loading ? <Loading /> :
                                filteredData.length !== 0 ?
                                    <>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Created</TableCell>
                                                    <TableCell>Updated</TableCell>
                                                    <TableCell align="right">Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(value => (
                                                    <TableRow key={value.id.toString()}>
                                                        <TableCell component="th" scope="row">
                                                            {value.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {convertIntoDate(value.created)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {convertIntoDate(value.updated)}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton edge="end" onClick={(e) => openMenu(e, value)}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>

                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={filteredData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </>
                                    : <Grid style={{ minHeight: 100 }} spacing={3} container justifyContent="center"
                                            alignItems="center">
                                        <Typography> No Flows to show </Typography>
                                    </Grid>
                            }
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

            <FlowForm {...{ flowControl, name: flowName, setProgress, handleAlert, handleDialogClose, flow, progress, history }} />

            <Dialog open={flowControl.delete} onClose={() => handleDialogClose('delete')}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this flow?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDelete} color='primary' size='small'> yes </Button>
                    <Button onClick={() => handleDialogClose('delete')} color='primary' size='small'> No </Button>
                    {progress ? <Box display="flex"> <CircularProgress color="secondary" /> </Box> : null}
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Flows;
