import React from "react";
import {Typography} from "@material-ui/core";
import {
    Apidetails,
    Connectordetails,
    Flowdetails,
    Thingdetails,
    Topicdetails
} from "./components";

export const StepContent = ({stepIndex, values, setValues, history, setLoading, handleAlert, loading, isStepValid}) => {
    const components = [
        <Thingdetails {...{values, setValues, setLoading, handleAlert, loading}} />,
        <Topicdetails {...{values, setValues, setLoading, handleAlert, loading}} />,
        <Connectordetails {...{values, setValues, handleAlert, setLoading, loading}} />,
        <Flowdetails {...{values, setValues, handleAlert, setLoading, loading, history}} />,
        <Apidetails {...{values, setValues, handleAlert, setLoading, loading}} />,
    ];

    return stepIndex < components.length ? (
        <>
            {components[stepIndex]}
            {!isStepValid &&
            <Typography variant={"subtitle2"} align={"center"} color="error">
                Please fill out the required fields to proceed.
            </Typography>}
        </>
    ) : 'All processes have been completed';
};