import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline";
import PasswordUpdate from "../Password/PasswordUpdate";

const Settings = () => {
    return (
        <>
            <CssBaseline/>
            <PasswordUpdate/>
        </>
    );
};

export default Settings;
