import axios from 'axios';
import {errorHandler} from "./errorHandler";

export const base_url = process.env.REACT_APP_BASE_URL;

const topics_url = base_url + '/topics';
const mqtt_url = base_url + '/mqtt';
const things_url = base_url + '/things';
const acl_url = base_url + '/acl';
const dashboard_url = base_url + '/dashboard';
const databases_url = base_url + '/data-source';
const databases_mapping_url = base_url + '/data-source-mapping';
const notifications_url = base_url + '/notifications';
const nodered_url = base_url + '/nodered';
const apis_url = base_url + '/predictions';


export const getTokenFromLocalStorage = () => {
    const token = localStorage.getItem('token');
    return token ? `Bearer ${token}` : null;
}

export const getUserFromLocalStorage = () => {
    const userString = localStorage.getItem('user');
    if (userString) {
        return JSON.parse(userString);
    } else {
        return null; // or handle the absence of user data in localStorage in a way that suits your application
    }
}

export const isAdmin = () => {
    const user = getUserFromLocalStorage();
    return user && user.admin === 1;
};

export const getQueryParam = (param) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(param);
}

export const convertIntoDate = (input) => {
    try {
        let dateObj;

        // Check if the input is a timestamp (number or numeric string)
        if (!isNaN(input)) {
            // Convert numeric string to number if necessary
            const timestamp = typeof input === 'string' ? parseInt(input, 10) : input;
            dateObj = new Date(timestamp);
        } else {
            // Assume the input is a date string in a format like "2024-07-17T08:56:04.000Z"
            dateObj = new Date(input);
        }

        // Check for invalid date
        if (isNaN(dateObj.getTime())) {
            throw new Error('Invalid date');
        }

        // Format the date object to a readable string
        return dateObj.toLocaleString();
    } catch (error) {
        return null;
    }
};

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export const handleRedirectToLogin = () => {
    const loginUrl = process.env.REACT_APP_DNS_URL;

    if (loginUrl) {
        window.location.href = loginUrl;
    } else {
        console.error("Login URL not defined in environment variables");
    }
};

export const withErrorHandlingAndLoading = (asyncFunction, setLoading, handleAlert) => {
    return async function(...args) {
        try {
            await setLoading(true);
            await asyncFunction(...args);
            await setLoading(false);
        } catch (error) {
            console.error('Caught Error:', error);

            if (error && error.response) {
                // Error response from server
                const { status, data } = error.response;
                handleAlert(true, `Response Error: ${status} - ${data.message || data}`, 'error');
            }
            else if (error && error.request) {
                // No response from server
                handleAlert(true, 'Network Error: No response from server', 'error');
            }
            else if (error && error.message) {
                // General error with a message
                handleAlert(true, `Error Occurred: ${error.message}`, 'error');
            }
            else {
                // Unknown error structure or error is undefined
                handleAlert(true, 'An unknown error occurred', 'error');
            }
        } finally {
            setLoading(false);
        }
    };
}

axios.defaults.headers.common['Authorization'] = getTokenFromLocalStorage();

export const handleUpload = (files , setUploadPercentage) => {
    const formData = new FormData();
    formData.append('sslcert', files[0]);
    formData.append('sslkey', files[1]);
    formData.append('sslca', files[2]);

    axios.post(`${mqtt_url}/upload-ssl`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: progressEvent => {
            setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
        }
    }).then(response => {
        return  response.data;
    }).catch(error => {
        errorHandler(error);
    });
}

export const fetchKafkaTopics = async () => {
    try {
        const response = await axios.get(`${topics_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchTopicMessages = async (topicUID) => {
    try {
        const response = await axios.get(`${topics_url}/messages/${topicUID}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchLatestMessages = async () => {
    try {
        const response = await axios.get(`${topics_url}/latest/messages`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchMessageCounts = async (selectedTopics) => {
    try{
        const response = await axios.post(`${topics_url}/messages_count`, { topicIds : selectedTopics });
        return  response.data;
    }
     catch (error) {
         errorHandler(error);
    }
}

export const deleteTopics = async (selectedRowIds) => {
    try {
        // Map over selected row IDs and create an array of delete promises
        const deletePromises = selectedRowIds.map(async topicId => {
            await axios.delete(`${topics_url}/${topicId}`);
            return topicId;
        });

         await Promise.all(deletePromises);

         return deletePromises;

    } catch (error) {
        errorHandler(error);
    }
}

export const addTopic = async (values) => {
    try {
        const response = await axios.post(`${topics_url}`, values, );
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const pauseTopics = async (selectedTopics) => {
    try{
        const response = await axios.post(`${topics_url}/pause-topics`, { topicIds : selectedTopics });
        return  response.data;
    }
    catch (error) {
        errorHandler(error);
    }
}

export const resumeTopics = async (selectedTopics) => {
    try{
        const response = await axios.post(`${topics_url}/resume-topics`, { topicIds : selectedTopics });
        return  response.data;
    }
    catch (error) {
        errorHandler(error);
    }
}

export const fetchThings = async () => {
    try {
        const response = await axios.get(`${things_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const addThing = async (thing) => {
    try {
        const response = await axios.post(`${things_url}`, thing);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const updateThing = async (id,thing) => {
    try{
        const response = await axios.put(`${things_url}/${id}`, thing);
        return response.data;
    }
    catch (error) {
        errorHandler(error);
    }
}

export const deleteThing = async (id) => {
    try {
        const response = await axios.delete(`${things_url}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchAclRecords = async (thingId) => {
    try {
        const response = await axios.get(`${acl_url}/aclUser/${thingId}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const AddAclRecord = async (acl) => {
    try {
        const response = await axios.post(`${acl_url}`, acl);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const deleteAclRecord = async (aclUserId,id) => {
    try {
        const response = await axios.delete(`${acl_url}/${aclUserId}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchDashboardInfo = async () => {
    try {
        const response = await axios.get(`${dashboard_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchDatabases = async () => {
    try {
        const response = await axios.get(`${databases_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const addDatabase = async (db) => {
    try {
        const response = await axios.post(`${databases_url}`, db);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const updateDatabase = async (id,database) => {
    try{
        const response = await axios.put(`${databases_url}/${id}`, database);
        return response.data;
    }
    catch (error) {
        errorHandler(error);
    }
}

export const deleteDatabase = async (id) => {
    try {
        const response = await axios.delete(`${databases_url}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchDatabasesMapping = async () => {
    try {
        const response = await axios.get(`${databases_mapping_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const deleteDatabasesMapping = async (id) => {
    try {
        const response = await axios.delete(`${databases_mapping_url}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}


export const fetchSourceMessages = async (id, page, size) => {
    try {
        const response = await axios.get(`${databases_mapping_url}/messages/${id}?page=${page}&size=${size}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}


export const fetchNotifications = async () => {
    try {
        const response = await axios.get(`${notifications_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const addNotification = async (notification) => {
    try {
        const response = await axios.post(`${notifications_url}`, notification);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const updateNotification = async (id) => {
    try{
        const response = await axios.put(`${notifications_url}/${id}`);
        return response.data;
    }
    catch (error) {
        errorHandler(error);
    }
}

export const deleteNotifications = async (ids) => {
    try {
        const response = await axios.post(`${notifications_url}/bulk-delete`, ids);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const fetchFlows = async () => {
    try {
        const response = await axios.get(`${nodered_url}/flows`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const addFlow = async (flow) => {
    try {
        const response = await axios.post(`${nodered_url}/flows`, flow);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const deleteFlow = async (id) => {
    try {
        const response = await axios.delete(`${nodered_url}/flows/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const updateFlow = async (id , name) => {
    try {
        const response = await axios.put(`${nodered_url}/flows/${id}`,{name});
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

//for later

export const fetchApis = async () => {
    try {
        const response = await axios.get(`${apis_url}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const addApi = async (api) => {
    try {
        const response = await axios.post(`${apis_url}`, api);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const deleteApi = async (id) => {
    try {
        const response = await axios.delete(`${apis_url}/${id}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}
export const updateApi = async (values) => {
    try {
        const response = await axios.put(`${apis_url}/${values.id}`,values);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}

export const listLibs = async () => {
    try {
        const response = await axios.get(`${base_url}/allSDK`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}


export const downloadLib = async (data) => {
    try {
        window.open(`${base_url}/download/${data}`);
    } catch (error) {
        errorHandler(error);
    }
}

export const deleteLib = async (name) => {
    try {
        const response = await axios.get(`${base_url}/deleteSchema/${name}`);
        return response.data;
    } catch (error) {
        errorHandler(error);
    }
}














