import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { Card, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';  // Import the useTheme hook

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
    },
}));

const BarChart = ({ data }) => {
    const classes = useStyles();
    const theme = useTheme();  // Access the theme using the useTheme hook

    const chartData = {
        labels: data.map(item => item.label),
        datasets: [
            {
                label: 'Messages Count',
                data: data.map(item => item.value),
                backgroundColor: theme.palette.primary.main, // Use primary color from the theme
                borderColor: theme.palette.primary.dark, // Use the darker shade of primary color
                borderWidth: 1,
                barPercentage: 0.05,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <Card className={classes.root}>
            <CardHeader title="Messages Count per Topic" />
            <div>
                <Bar data={chartData} options={options} />
            </div>
        </Card>
    );
};

BarChart.propTypes = {
    className: PropTypes.string,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired,
        })
    ).isRequired,
};

export default BarChart;
