import {Box, Card, CardContent, IconButton, Typography} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";

export const SmallCard = ({name, timestamp, message, onOpen, onDelete}) => {
    return (
        <Card >
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        {name}
                    </Typography>
                    <Box>
                        {onOpen &&
                        <IconButton onClick={() => onOpen(message)}>
                            <OpenInNewIcon color={"primary"}/>
                        </IconButton>
                        }
                        {onDelete &&
                        <IconButton onClick={() => onDelete(message)}>
                            <DeleteIcon/>
                        </IconButton>
                        }
                    </Box>
                </Box>
                <Typography variant="body2" color="textSecondary">
                    {timestamp}
                </Typography>
            </CardContent>
        </Card>
    );
};